import { Component, OnInit, ViewChild, HostListener, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CartService } from 'src/app/services/cart.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from '../../auth/service/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from 'src/app/services/language/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations:[
    trigger("animateMenu",[
      transition(":enter",[
        query("*", [
          style({opacity: 0, transform: "translateY(-50%)"}),
          stagger(50,[
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity: 1, transform: "none"}))
          ])
        ])
      ])
    ])
  ]
})



export class HeaderComponent implements OnInit {
  openNav = false;
  showNav: boolean = false;
  currentLanguage: "vi" | "en" | "cn" = "vi";

  settings: any = null;
  user: any;
  token: string | null = null;
  cartItemCount: number = 0;
  searchKeyword: string = '';

  responsiveMenuVisible: Boolean = false;
  pageYPosition: number;
  //languageFormControl: UntypedFormControl= new UntypedFormControl();
  cvName: string = "";
  isloading:boolean=false;

  constructor(
    private router: Router,
    //public analyticsService: AnalyticsService,
    public languageService: LanguageService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {

    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });

    this.authService.token$.subscribe((token) => {
      this.token = token;
    });
    
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
    //this.user = this.authService.user;
    //this.token = this.authService.token;
    if (isPlatformBrowser(this.platformId)) {
      this.settingsService.settings$.subscribe(
        (data) => {
          if(data){
            this.settings = data;
            this.isloading=true;
          }
        },
        (error) => {
          console.error('Error fetching settings:', error);
          this.isloading = false;
        }
      );
      this.cartService.cartItemCount$.subscribe(count => {
        this.cartItemCount = count;
      });
    }

  }

  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }
    this.responsiveMenuVisible=false;
  }
  toggleNav() {
    this.openNav = !this.openNav;
  }
  searchProducts() {
    if (this.searchKeyword.trim()) {
        const slugifiedKeyword = this.toSlug(this.searchKeyword.trim());
        this.router.navigate(['/'+this.currentLanguage,'tim-kiem', slugifiedKeyword]);
    }
  }

  // Hàm chuyển đổi chuỗi thành slug với dấu tiếng Việt
  private toSlug(input: string): string {
      return input.toLowerCase().trim().replace(/\s+/g, '-').replace(/-+/g, '-');
  }
  enableNav() {
    this.showNav = true;
  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
    getScrollPosition(event) {
        this.pageYPosition=window.pageYOffset
    }
    changeLanguage(lang: 'vi' | 'en' | 'cn'): void {
      this.languageService.changeLanguage(lang);
      return;
    }
}
