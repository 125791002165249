<main class="bg-gray-100 py-3">
    <!-- Hiển thị skeleton-box nếu đang tải -->
    <div *ngIf="isLoading; else content" class="container">
      <div class="skeleton-box" style="height: 400px;"></div>
      <div class="productHot mb-5 bg-white p-3">
        <h2 class="title_page" data-aos="fade-up" data-aos-duration="1000">{{ "sanphambanchay" | translate }}</h2>
        <div class="skeleton-box" style="height: 400px;"></div>
      </div>
      <div class="group_product mb-5 bg-white p-3">
        <div class="skeleton-box" style="height: 400px;"></div>
      </div>
      <div class="group_product mb-5 bg-white p-3">
        <div class="skeleton-box" style="height: 400px;"></div>
      </div>
    </div>
  
    <!-- Hiển thị nội dung thực tế khi tải xong -->
    <ng-template #content>
      <app-slider [sliders]="slider"></app-slider>
      <app-product-sale [productSale]="productSale" [currentLanguage]="currentLanguage"></app-product-sale>
      <app-product-category
        [categories]="categories"
        [currentLanguage]="currentLanguage"
        [why]="why">
      </app-product-category>
    </ng-template>
</main>  