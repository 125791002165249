import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SafeHtml, Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { URL_WEBSITE } from 'src/app/config/config';
import { SettingsService } from 'src/app/services/settings.service';
import { HomeService } from '../home/service/home.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {
  contactForm: FormGroup;
  settings: any = null;
  title:string="Liên hệ";
  isLoading: boolean = false;
  sanitizedIframe!: SafeHtml;
  constructor(
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private homeService: HomeService,
    private sanitizer: DomSanitizer
  ){
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10,11}$')]],
      email: ['', [Validators.required, Validators.email]],
      subject: [''],
      message: [''],
    });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.settings$.subscribe(
      (data) => {
        if (data) {
          this.settings = data;
          const iframeData = data.map;
          this.sanitizedIframe = this.sanitizer.bypassSecurityTrustHtml(iframeData);
        }
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    this.homeService.seoSetting('contact').subscribe((resp:any)=>{
      if(resp.code==200){
        this.settingsService.updateMetaTags(resp.seo.title, resp.seo.description, resp.seo?.image?.thumbnail, "");
        this.settingsService.updateSchema(resp.seo.code_schema);
      }else{
        this.settingsService.settings$.subscribe((settings:any) => {
          if(settings){
            this.settingsService.updateMetaTags(this.title, settings?.description, settings?.logo, settings?.title);
          }
        });
      }
    })
    
  }
  send(){
    this.isLoading = true;
    if (this.contactForm.invalid) {
      this.toastr.error('Vui lòng kiểm tra lại thông tin');
      this.isLoading = false;
      return;
    }

    const orderData = {
      ...this.contactForm.value,
    };
    this.homeService.sendContact(orderData).subscribe((resp:any) =>{
      this.toastr.success('Gửi liên hệ thành công! Chúng tôi sẽ liên hệ bạn sớm nhất');
    },(error:any) =>{
      this.isLoading = false;
      this.toastr.error('Gửi liên lệ thất bại. Vui lòng thử lại.');
    })
  }
}
