import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';

declare var $:any;
declare function detailProduct($: any): void;
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.css'
})
export class SliderComponent {
  @Input() product: any;
  private sliderInstance: any;
  constructor(
    private cd: ChangeDetectorRef,
  ){}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue) {
      setTimeout(() => {
        this.destroySlider();  // Hủy slider trước khi khởi tạo lại
        this.initSlider();     // Khởi tạo slider mới
      }, 300);
    }
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  initSlider() {
    if (!this.sliderInstance) {
      this.sliderInstance = $('#image-gallery').lightSlider({
        gallery: true,
        item: 1,
        thumbItem: 4,
        slideMargin: 0,
        speed: 500,
        pause: 6000,
        auto: true,
        loop: true,
        onSliderLoad: () => {
          $('#image-gallery').removeClass('cS-hidden');
        }
      });
    }

    // Hủy bỏ fancybox nếu đã khởi tạo
    $.fancybox.destroy();

    $('[data-fancybox="images"]').fancybox({
      thumbs: {
        autoStart: true,
        axis: 'x'
      }
    });
  }

  destroySlider() {
    if (this.sliderInstance) {
      this.sliderInstance.destroy(); // Hủy slider hiện tại
      this.sliderInstance = null;    // Reset lại instance
    }
  }
}
