<main class="bg-gray-100 py-5">

    <!-- profile area start -->
    <section class="profile__area pt-120 pb-120">
     <div class="container">
           <div class="row">
              <div class="col-xxl-4 col-lg-4">
                 <div class="profile__tab me-5 bg-white">
                    <a class="nav-link " routerLink="/{{currentLanguage}}/profile"><span><i class="fas fa-user"></i></span>{{"thongtintaikhoan" | translate}}</a>
                    <a class="nav-link " routerLink="/{{currentLanguage}}/lich-su-mua-hang"><span><i class="fas fa-clipboard-list-check"></i></span>{{"lichsumuahang" | translate}}</a>
                    <a class="nav-link active" routerLink="/{{currentLanguage}}/change-pass"><span><i class="fas fa-lock"></i></span>{{"doimatkhau" | translate}}</a>
                    <a class="nav-link" routerLink="/{{currentLanguage}}/logout"><span><i class="fas fa-times"></i></span>{{"thoat" | translate}}</a>
                    <span id="marker-vertical" class="tp-tab-line d-none d-sm-inline-block"></span>
                 </div>
              </div>
              <div class="col-xxl-8 col-lg-8">
                <div class="profile_form bg-white p-md-5 p-3">
                    <div class="row">
                        <div class="col-xxl-6 col-md-6">
                            <div class="tp-profile-input-box">
                               <div class="tp-profile-input">
                                  <input name="new_pass" id="new_pass" type="password" class="form-control" [(ngModel)]="newpassword">
                               </div>
                               <div class="tp-profile-input-title">
                                  <label for="new_pass">{{"matkhaumoi" | translate}}</label>
                               </div>
                            </div>
                         </div>
                         <div class="col-xxl-6 col-md-6">
                            <div class="tp-profile-input-box">
                               <div class="tp-profile-input">
                                  <input name="con_new_pass" id="con_new_pass" type="password" class="form-control" [(ngModel)]="renewpassword">
                               </div>
                               <div class="tp-profile-input-title">
                                  <label for="con_new_pass">{{"xacnhanmatkhau" | translate}}</label>
                               </div>
                            </div>
                         </div>
                    </div>
                    <div class="text-center">
                        <div *ngIf="isLoading" class="loading-indicator">
                           {{"dangxuly" | translate}}
                        </div>
                        <input type="submit" value="Cập nhật" class="btn btn-primary" [disabled]="isLoading" (click)="changePass()">
                    </div>
                </div>
              </div>
           </div>
        </div>
    </section>
 <!-- profile area end -->

  </main>