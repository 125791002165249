<main class="bg-gray-100 py-3">
    <div class="container bg-white p-3">
        <div class="content1x postDetail">
            <h1 class="title">{{ article?.name }}</h1>
            <div class="date">
                <i class="fas fa-calendar-alt"></i> {{ article?.created_at }}
            </div>
            <div class="my-3" [innerHTML]="article?.content"></div>
        </div>
        
    </div>
</main>