import { afterNextRender, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from './services/settings.service';
import { LanguageService } from './services/language/language.service';
import { HomeService } from './components/home/service/home.service';
import { ApiLoadServiceService } from './services/api-load-service.service';
import { filter } from 'rxjs/operators';
import { HeaderComponent } from './components/general/header/header.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Tajako';
  settings:any;
  codeInput: string = '';  // Mã code mà người dùng nhập
  correctCode: string = '';  // Mã code chính xác (có thể lấy từ API hoặc thiết lập tĩnh)
  isAuthenticated: boolean = false;  // Trạng thái xác thực
  errorMessage: string = '';  // Thông báo lỗi
  tokenExpiryTime: number = 86400000;  // Thời gian hết hạn (1 ngày = 86400000ms)
  @ViewChild(HeaderComponent) headerComponent!: HeaderComponent;
  gaCode!: SafeHtml;
  schemaCode!: SafeHtml;

  constructor(
    private settingsService: SettingsService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private languageService: LanguageService,
    private homeService: HomeService,
    private apiLoadService: ApiLoadServiceService,
    private sanitizer: DomSanitizer,
    ){
      afterNextRender(()=>{
        $(window).on("load",function(){
          $("#loading").fadeOut(100);
        })
      })

      this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
        const currentUrl = this.getCurrentUrl();
        this.updateCanonical(currentUrl);
      });
    }
  ngOnInit(): void{
    this.languageService.initLanguage();
    this.languageService.language$.subscribe((lang: string) => {
      if (isPlatformBrowser(this.platformId) || isPlatformServer(this.platformId)) {
        this.document.documentElement.lang = lang;
      }
    });
    Promise.all([
      this.settingsService.loadSettings(),
      this.homeService.fetchFooter()
    ]).then(() => {
      // Báo hiệu các API cơ bản đã load xong
      this.apiLoadService.setBaseApisLoaded();
    });
    setTimeout(() => {
      this.headerComponent.enableNav(); // Kích hoạt hiển thị app-nav
    }, 1000); // Thời gian giả lập
    //AOS.init();
    // if (isPlatformBrowser(this.platformId) || isPlatformServer(this.platformId)) {
    //   this.settingsService.updateGA();
    //   this.settingsService.updateSchema();
    // }
  }
  private getCurrentUrl(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.href;
    } else if (isPlatformServer(this.platformId)) {
      const baseUrl = this.document.location.origin || 'https://example.com';
      const path = this.document.location.pathname || '';
      return `${baseUrl}${path}`;
    }
    return '';
  }

  // Hàm cập nhật canonical
  private updateCanonical(url: string): void {
    let link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
    }
    link.setAttribute('href', url);
  }
  private setHtmlLang(lang: string): void {
    if (isPlatformBrowser(this.platformId) || isPlatformServer(this.platformId)) {
      this.document.documentElement.lang = lang;
    }
  }
  checkAuthentication(): void {
    if (isPlatformBrowser(this.platformId)) {
      const storedCodeData = localStorage.getItem('authCode');
      if (storedCodeData) {
        const parsedData = JSON.parse(storedCodeData);
        const now = new Date().getTime();

        // Kiểm tra xem mã code có còn hiệu lực không
        if (now < parsedData.expiry) {
          this.isAuthenticated = true;  // Xác thực thành công, mã còn hiệu lực
        } else {
          localStorage.removeItem('authCode');  // Xóa mã code nếu hết hạn
          this.isAuthenticated = false;
        }
      } else {
        this.isAuthenticated = false;  // Nếu không có mã code, yêu cầu nhập
      }
    }
  }

  // Phương thức kiểm tra mã code khi người dùng nhập
  checkCode(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.codeInput === this.correctCode) {
        this.isAuthenticated = true;
        this.errorMessage = '';

        // Lưu mã code và thời gian hết hạn vào localStorage
        const now = new Date().getTime();
        const expiry = now + this.tokenExpiryTime;  // Thời gian hết hạn là 1 ngày

        const authData = {
          code: this.codeInput,
          expiry: expiry
        };

        localStorage.setItem('authCode', JSON.stringify(authData));  // Lưu vào localStorage
      } else {
        this.errorMessage = 'Mã code không đúng. Vui lòng thử lại!';
      }
    }
  }
}
