import { Component, Input } from '@angular/core';
import { HomeService } from '../service/home.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product-sale',
  templateUrl: './product-sale.component.html',
  styleUrl: './product-sale.component.css'
})
export class ProductSaleComponent {
  @Input() productSale: any[] = [];
  @Input() currentLanguage: any = '';
  @Input() isLoading: boolean = true;
  customOptions: OwlOptions = {
    loop: true,
    margin:20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }
  constructor(
    private homeService: HomeService,
  ){}
  ngOnInit(): void {
    
  }
}
