import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    CommonModule, FormsModule,NgbPaginationModule, RouterModule,TranslateModule
  ]
})
export class SearchModule { }
