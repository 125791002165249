import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { URL_SERVICEIO } from '../../../config/config';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, catchError, switchMap, shareReplay, tap, distinctUntilChanged } from 'rxjs/operators';
import { SettingsService } from '../../../services/settings.service';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from 'src/app/services/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private footerSubject = new BehaviorSubject<any>(null);
  private menusSubject = new BehaviorSubject<any>(null);
  private menusSubjectChild = new BehaviorSubject<any>(null);
  private catesSubject = new BehaviorSubject<any>(null);
  private languageSubscription: Subscription;

  footer$ = this.footerSubject.asObservable();
  menus$ = this.menusSubject.asObservable();
  menusChild$ = this.menusSubjectChild.asObservable();
  cates$ = this.catesSubject.asObservable();

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: object,
    private languageService: LanguageService
  ) {
    let currentLanguage = this.languageService.currentLanguage; // Truy cập getter như một thuộc tính

    // this.languageSubscription = this.languageService.language$.pipe(distinctUntilChanged()).subscribe((newLang) => {
    //   // Kiểm tra nếu ngôn ngữ thay đổi
    //   if (newLang !== currentLanguage) {
    //     this.fetchFooter(); // Gọi lại fetchFooter khi ngôn ngữ thay đổi
    //     //this.fetchMenus(); // Gọi lại fetchMenus khi ngôn ngữ thay đổi
    //     //this.fetchCategories();
    //     currentLanguage = newLang; // Cập nhật ngôn ngữ hiện tại
    //   }
    // });
  }

  slider(): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/slider`;
    return this.http.get(URL);
  }

  // home(lang: string): Observable<any> {
  //   const URL = `${URL_SERVICEIO}/frontEnd/home?lang=${lang}`;
  //   return this.http.get(URL).pipe(
  //     tap((resp: any) => {
  //       this.updateMetaTags(resp);
  //     })
  //   );
  // }
  home(): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/home?lang=${this.languageService.currentLanguage}`;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.updateMetaTags(resp);
      })
    );
  }

  // Đổi phương thức fetchFooter thành public
  public fetchMenus() {
    const lang = this.languageService.currentLanguage;
    const URL = `${URL_SERVICEIO}/frontEnd/menus?lang=${lang}`;
    return this.http.get(URL);
  }
  // public fetchCategories() {
  //   const lang = this.languageService.currentLanguage;
  //   const URL = `${URL_SERVICEIO}/frontEnd/categories?lang=${lang}`;
  //   return this.http.get(URL);
  // }
  public fetchCategories() {
    const lang = this.languageService.currentLanguage;
    const URL = `${URL_SERVICEIO}/frontEnd/cates?lang=${lang}`;
    return this.http.get(URL);
  }
  public fetchCategoryChilds(): void {
    const lang = this.languageService.currentLanguage;
    if (this.menusSubjectChild.getValue()?.lang === lang) {
      return;
    }
    const URL = `${URL_SERVICEIO}/frontEnd/categoryChilds?lang=${lang}`;
    this.http.get(URL).pipe(
      tap((data) => this.menusSubjectChild.next({ ...data, lang }))
    ).subscribe();
  }

  // Đổi phương thức fetchFooter thành public
  public fetchFooter() {
    const lang = this.languageService.currentLanguage;
    const URL = `${URL_SERVICEIO}/frontEnd/footer?lang=${lang}`;
    return this.http.get(URL);
  }

  formatCurrency(value: number): string {
    return value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }).replace('₫', 'đ');
  }

  getAbouts(page: number, type: string): Observable<any> {
    return this.languageService.language$.pipe(
      distinctUntilChanged(),
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/blogs?page=${page}&type=${type}&lang=${lang}`;
        return this.http.get(URL);
      })
    );
  }

  getAboutDetail(id: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/blogs/${id}?lang=${lang}`;
        return this.http.get(URL).pipe(
          tap((resp: any) => {
            this.updateMetaTags(resp.blog, resp.blog?.title);
          })
        );
      })
    );
  }

  getArticleDetail(id: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/article/${id}?lang=${lang}`;
        return this.http.get(URL).pipe(
          tap((resp: any) => {
            this.updateMetaTags(resp.article, resp.article?.title);
          })
        );
      })
    );
  }
  seoSetting(type: string): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/seo/${type}`;
    return this.http.get(URL);
  }

  sendContact(data: any): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/contact?lang=${lang}`;
        return this.http.post(URL, data);
      })
    );
  }
  private updateMetaTags(data: any, title?: string): void {
    this.settingsService.settings$.subscribe((settings: any) => {
      const title_custom = title || settings?.title || settings?.name;
      const description_custom = data?.description || settings?.description;
      const imageUrl = data?.photo || settings?.logo;

      this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings?.title);
    });
  }

  ngOnDestroy(): void {
    // Hủy subscribe để tránh rò rỉ bộ nhớ
    if (this.languageSubscription && !this.languageSubscription.closed) {
      this.languageSubscription.unsubscribe();
    }
  }
}
