import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../service/product.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css'
})
export class CategoriesComponent {
  settings: any = [];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  brands: any[] = [];
  origins: any[] = [];
  guarantees: any[] = [];
  optionsSearch: any[] = [];
  filteredBrands:any=[];
  priceFilter:string='';
  isShowOrderby: boolean = false;
  brandId:string ='';
  priceId:string='';
  optionId:string='';
  originId:string='';
  guaranteeId:string='';
  totalSearch:number = 0;
  categorie_slug:string='';
  categorie:any=[];
  categorie_id:string='';
  category_childs:any=[];
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  isContentExpanded: boolean = false;
  currentSort: string = 'new'; // Giá trị sắp xếp mặc định
  sortedProducts: any[] = [];
  categories:any=[];

  optionSelected:any=[];
  brandSelected:any=[];
  priceSelected:any=[];
  dataLoaded:boolean=false;

  
  constructor(
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
  ){}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.priceFilter = (queryParams['p']) ? queryParams['p'] : '';
      this.brandId = (queryParams['brand']) ? queryParams['brand'] : '';
      this.optionId = (queryParams['o']) ? queryParams['o'] : '';
      this.originId = (queryParams['sx']) ? queryParams['sx'] : '';
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;

      //if (this.priceFilter || this.brandId || this.optionId) this.getProducts(this.currentPage);
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.categorie_slug=resp.slugCategorie;
      this.categorie_id=resp.idCategorie;

      this.productService.getCategoriesDetail(this.categorie_id).subscribe((resp:any) => {
        this.categorie = resp.categorie;
        this.categorie_id = resp.categorie.id;
        this.optionSelected = resp.options;
        this.brandSelected = resp.brands;
        this.priceSelected = resp.prices;
        this.category_childs = resp.categories_child;
        if(this.category_childs.length>0){
          this.totalPages = resp.total;
        }else{
          this.loadFilters();
          this.category_childs=[];
          this.getProducts(this.currentPage);
        }
        
        if(this.optionsSearch.length==0){
          this.productService.getOptionSearch(this.categorie_id, this.brandId).subscribe((resp: any) => {
            this.optionsSearch = resp.option_lists;
          });
        }
        this.cdr.detectChanges();
      })
    })
    
    // combineLatest([
    //   this.activedRoute.params,
    //   this.activedRoute.queryParams
    // ]).subscribe(([params, queryParams]) => {
    //   this.getProducts(this.currentPage);
    // });
  }
  onCategoriesLoaded(categories: any[]): void {
    this.categories = categories;  // Nhận dữ liệu từ `nav` component
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, this.categorie_id, this.priceFilter,this.brandId, this.currentSort, this.optionId, this.originId).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  getCategoryChilds(page=1): void {
    this.productService.getCategoryChild(page, this.categorie_id).subscribe((data: any) => {
      this.products = data.products;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
    this.getProducts(page);
  }
  loadPageChild(page: number): void {
    this.currentPage = page;
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
    this.getProducts(page);
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
  loadFilters() {
    this.productService.getPriceSearchs(this.categorie_id).subscribe((resp: any) => {
      this.prices = resp.prices;
      this.brands = resp.brands;
      this.origins = resp.origins;
      this.guarantees = resp.guarantees;
      this.dataLoaded = true;
    });
  }

  onFilterChanged(filters: any) {
    this.applyFilters(filters);
  }

  applyFilters(filters: any) {
    this.brandId = filters.brands.map((brand: any) => brand.id).join(',');
    this.priceFilter = filters.prices.map((price: any) => price.id).join(',');
    this.optionId = filters.options.map((option: any) => option.id).join(',');
    this.originId = filters.origins.map((origin: any) => origin.id).join(',');
    // const guaranteeIds = filters.guarantees.map((guarantee: any) => guarantee.id).join(',');

    this.router.navigate([], {
      queryParams: {
        brand: this.brandId,
        p: this.priceFilter,
        o: this.optionId,
        sx: this.originId,
        // g: guaranteeIds
      },
      queryParamsHandling: 'merge'
    });
    this.getProducts();
  }

  clearAllFilters() {
    this.router.navigate([], { queryParams: {} });
    this.totalSearch = 0;
    this.getProducts();
  }
  
  getActiveSortText() {
    switch (this.currentSort) {
      case 'popularity': return 'Bán chạy nhất';
      case 'new': return 'Mới nhất';
      case 'price': return 'Giá thấp đến cao';
      case 'price-desc': return 'Giá cao đến thấp';
      default: return '';
    }
  }
  sortProducts(orderBy: string) {
    this.currentSort=orderBy;
    this.getProducts();
    this.isShowOrderby=false;
  }
  showOrderby(){
    this.isShowOrderby = !this.isShowOrderby;
  }
}
