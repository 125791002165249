<!-- categories -->
<div class="tp-shop-widget mb-5 bg-white p-3">
    <h3 class="tp-shop-widget-title">{{'danhmucsanpham' | translate}}</h3>

    <div class="tp-shop-widget-content">
       <div class="tp-shop-widget-categories">
          <ul>
             <li *ngFor="let item of categories"><a routerLink="/{{currentLanguage}}/{{ item.slug }}-c{{ item.id }}">{{ item.name }}</a></li>
          </ul>
       </div>
    </div>
</div>
<!-- product rating -->
<div class="tp-shop-widget d-md-block d-none bg-white p-3">
    <h3 class="tp-shop-widget-title">{{'sanphambanchay' | translate}}</h3>

    <div class="tp-shop-widget-content">
      <div *ngIf="isLoading" class="product-important-skeleton tp-shop-widget-product">
         <div *ngFor="let skeleton of skeletonArray" class="skeleton-item d-flex flex-wrap">
           <div class="skeleton-image col-3"></div>
           <div class="skeleton-text col-9 ms-3"></div>
         </div>
      </div>
       <div class="tp-shop-widget-product" *ngIf="!isLoading">
          <div class="tp-shop-widget-product-item d-flex align-items-center mb-3 border-bottom pb-3" *ngFor="let product of productSale">
             <div class="tp-shop-widget-product-thumb col-3">
               <a routerLink="/{{currentLanguage}}/{{product.categorie_first?.slug}}/{{ product.slug }}-tjk{{ product.id }}">
                  <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="eager" onerror="this.onerror=null;this.src='assets/images/no-image.jpg';">
                  <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                  <ng-template #usePhoto>
                  <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                  </ng-template>
                </a>
             </div>
             <div class="tp-shop-widget-product-content col-9 ps-3">
                <div class="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                    <div class="tp-shop-widget-product-rating">
                        <span>
                           <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                           </svg>
                        </span>
                        <span>
                           <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                           </svg>
                        </span>
                        <span>
                           <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                           </svg>
                        </span>
                        <span>
                           <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                           </svg>
                        </span>
                        <span>
                           <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                           </svg>
                        </span>
                    </div>
                </div>
                <h4 class="tp-shop-widget-product-title">
                  <!-- <a [routerLink]="[currentLanguage,'/san-pham', product.categorie_third?.slug || product.categorie_second?.slug || product.categorie_first?.slug, product.slug]">
                        {{ product.name }}
                     </a> -->
                  <a routerLink="/{{currentLanguage}}/{{product.categorie_first?.slug}}/{{ product.slug }}-tjk{{ product.id }}">
                     {{ product.name }}
                  </a>
                </h4>
                <div class="tp-shop-widget-product-price-wrapper">
                     <span class="tp-shop-widget-product-price" *ngIf="product.priceSale > 0">
                        {{ product.priceSale | number:'1.0-0' }} đ
                     </span>
                     <span class="tp-shop-widget-product-price" *ngIf="product.priceSale <= 0">
                        {{'lienhe' | translate}}
                     </span>
                </div>
             </div>
          </div>
       </div>
    </div>
</div>
<!-- brand -->
<!-- <div class="tp-shop-widget mb-6">
    <h3 class="tp-shop-widget-title">Popular Brands</h3>

    <div class="tp-shop-widget-content ">
       <div class="tp-shop-widget-brand-list d-flex align-items-center justify-content-between flex-wrap">
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_01.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_02.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_03.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_04.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_05.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_06.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_07.png" alt="">
             </a>
          </div>
          <div class="tp-shop-widget-brand-item">
             <a href="#">
                <img src="assets/img/product/shop/brand/logo_08.png" alt="">
             </a>
          </div>
       </div>
    </div>
</div> -->