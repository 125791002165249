<main class="bg-gray-100 py-3">

    <!-- breadcrumb area start -->
    <section class="breadcrumb__area include-bg py-5" data-bg-color="#EFF1F5">
       <div class="container">
          <div class="row">
             <div class="col-xxl-12">
                <div class="breadcrumb__content position-relative z-index-1">
                   <h3 class="breadcrumb__title">{{"dathangthanhcong" | translate}}</h3>
                   <div class="breadcrumb__list">
                      <span><a routerLink="/{{currentLanguage}}">{{"trangchu" | translate}}</a></span>
                      <span>{{"thanhtoan" | translate}}</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- breadcrumb area end -->

    <!-- order area start -->
    <section class="tp-order-area pb-5">
       <div class="container">
          <div class="tp-order-inner" *ngIf="orderInfoVisible==true; else notFoundTemplate">
             <div class="row gx-0">
                <div class="col-lg-6 tp-order-details"  data-bg-color="#4F3D97">
                      <div class="tp-order-details-top text-center mb-70">
                         <div class="tp-order-details-icon">
                            <span>
                               <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M46 26V51H6V26" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M51 13.5H1V26H51V13.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M26 51V13.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M26 13.5H14.75C13.0924 13.5 11.5027 12.8415 10.3306 11.6694C9.15848 10.4973 8.5 8.9076 8.5 7.25C8.5 5.5924 9.15848 4.00269 10.3306 2.83058C11.5027 1.65848 13.0924 1 14.75 1C23.5 1 26 13.5 26 13.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M26 13.5H37.25C38.9076 13.5 40.4973 12.8415 41.6694 11.6694C42.8415 10.4973 43.5 8.9076 43.5 7.25C43.5 5.5924 42.8415 4.00269 41.6694 2.83058C40.4973 1.65848 38.9076 1 37.25 1C28.5 1 26 13.5 26 13.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                               </svg>
                            </span>
                         </div>
                         <div class="tp-order-details-content">
                            <h3 class="tp-order-details-title">{{"dathangthanhcong" | translate}}</h3>
                            <p>{{"chungtoiselienhesom" | translate}}</p>
                         </div>
                      </div>
                      <div class="tp-order-details-item-wrapper">
                         <div class="row">
                            <div class="col-sm-6">
                                <div class="tp-order-details-item">
                                   <h4>{{"madonhang" | translate}}:</h4>
                                   <p>#{{ order.madonhang }}</p>
                                </div>
                             </div>
                            <div class="col-sm-6">
                               <div class="tp-order-details-item">
                                  <h4>{{"ngaydat" | translate}}:</h4>
                                  <p>{{ order.created_at }}</p>
                               </div>
                            </div>
                            
                            <div class="col-sm-6">
                               <div class="tp-order-details-item">
                                  <h4>{{"phuongthucthanhtoan" | translate}}:</h4>
                                  <p>{{ getPaymentText() }}</p>
                               </div>
                            </div>
                         </div>
                      </div>
                </div>
                <div class="col-lg-6">
                   <div class="tp-order-info-wrapper bg-white">
                      <h4 class="tp-order-info-title">{{"thongtindonhang" | translate}}</h4>

                      <div class="tp-order-info-list">
                         <ul>

                            <!-- header -->
                            <li class="tp-order-info-list-header">
                               <h4>{{"sanpham" | translate}}</h4>
                               <h4>{{"dongia" | translate}}</h4>
                            </li>

                            <!-- item list -->
                            <li class="tp-order-info-list-desc"  *ngFor="let item of orderDetail">
                                <p>{{ item.product_name }}
                                    <ng-container *ngIf="item.note">
                                        <ng-container *ngFor="let variation of item.note.properties"><b> - {{ variation.attribute.name }}</b>: {{ variation.name }}</ng-container> 
                                    </ng-container>
                                <span> x {{ item.quantity }}</span></p>
                                <span style="white-space: nowrap; color:#f00;">{{ item.price | number:'1.0-0' }} đ</span>
                            </li>
                            <!-- total -->
                            <li class="tp-order-info-list-total">
                               <span>{{"tongcong" | translate}}</span>
                               <span>{{ order.total_price | number:'1.0-0' }} đ</span>
                            </li>
                         </ul>
                      </div>
                      <h4 class="tp-order-info-title mt-5">{{"thongtinnhanhang" | translate}}</h4>
                      <div class="tp-order-info-list">
                         <ul>
                            <li class="tp-order-info-list-desc d-flex">
                               <div class="col-3">{{"hoten" | translate}}</div>
                               <div class="col-9 ms-3">{{ order.hoten }}</div>
                            </li>
                            <li class="tp-order-info-list-desc d-flex">
                               <div class="col-3">{{"diachi" | translate}}</div>
                               <div class="col-9 ms-3">{{ order.diachi }}</div>
                            </li>
                            <li class="tp-order-info-list-desc d-flex">
                               <div class="col-3">{{"sodienthoai" | translate}}</div>
                               <div class="col-9 ms-3">{{ order.dienthoai }}</div>
                            </li>
                            <li class="tp-order-info-list-desc d-flex">
                               <div class="col-3">Email</div>
                               <div class="col-9 ms-3">{{ order.email }}</div>
                            </li>
                         </ul>
                      </div>
                   </div>
                </div>
             </div>
          </div>
            <ng-template #notFoundTemplate>
                <div class="alert alert-warning w-100">Đơn hàng <b>#{{orderID}}</b> không tồn tại</div>
            </ng-template>
       </div>
    </section>
    <!-- order area end -->

 </main>