import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { URL_WEBSITE } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from '../auth/service/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  checkoutForm: FormGroup;
  cartItems: any[] = [];
  user: any;
  token: string | null = null;
  isLoading: boolean = false;
  settings:any=[];
  title:string="Thanh toán";

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    public languageService: LanguageService,
    private translate: TranslateService,
  ) {
    this.authService.token$.subscribe((token) => {
      this.token = token;
    });
    
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });

    this.checkoutForm = this.fb.group({
      userId: [this.user?.id || ''],
      hoten: [this.user?.name || '', Validators.required],
      address: [this.user?.address || '', Validators.required],
      phone: [this.user?.phone || '', [Validators.required, Validators.pattern('^[0-9]{10,11}$')]],
      email: [this.user?.email || '', [Validators.required, Validators.email]],
      noted: [''],
      agree: [false, Validators.requiredTrue],
      payment: ['cod']
    });
  }

  ngOnInit(): void {
    this.updateTitle();
    this.languageService.language$.subscribe((lang) => {
      this.updateTitle();
      this.currentLanguage = lang;
    });
    this.settingsService.settings$.subscribe(
      (data) => {
        if(data){
          this.settings = data;
          this.updateMetaTags();
        }
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    this.loadCartItems();
  }
  private updateTitle(): void {
    this.translate.get('thanhtoan').subscribe((res: string) => {
      this.title = res; // Cập nhật tiêu đề
    });
  }
  loadCartItems(): void {
    this.cartItems = this.cartService.getCartItems();
  }
  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => total + item.price*item.quantity, 0);
  }
  onSubmit(){
    this.isLoading = true;
    if (this.checkoutForm.invalid) {
      this.toastr.error('Vui lòng kiểm tra lại thông tin và đồng ý với điều khoản của chúng tôi');
      this.isLoading = false;
      return;
    }

    const orderData = {
      ...this.checkoutForm.value,
      items: this.cartService.getCartItems()
    };
    this.cartService.createOrder(orderData, this.token).subscribe((response:any) =>{
        this.toastr.success('Đặt hàng thành công!');
        // Xử lý logic sau khi đặt hàng thành công (ví dụ: chuyển hướng, xóa giỏ hàng,...)
        this.cartService.clearCart();
        this.isLoading = false;
        this.router.navigate(['/order-confirmation/'+response.order_id]);
      },(error) =>{
        this.isLoading = false;
        this.toastr.error('Đặt hàng thất bại. Vui lòng thử lại.');
      }
    );
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
