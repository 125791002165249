import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataServiceService } from 'src/app/services/shared-data-service.service';
import { BrandService } from '../service/brand.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-detail-brand',
  templateUrl: './detail-brand.component.html',
  styleUrl: './detail-brand.component.css'
})
export class DetailBrandComponent {
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  dataLoaded:boolean=false;

  brand_slug:string='';
  brand:any=[];
  brand_id:string='';
  categories:any=[];
  isContentExpanded: boolean = false;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  
  constructor(
    private brandService: BrandService,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private sharedDataService: SharedDataServiceService,
    public languageService: LanguageService,
  ){}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.brand_slug = resp.slug;
      this.brandService.getBrandsDetail(this.brand_slug).subscribe((response:any) => {
        this.brand=response.brand;
        this.brand_id=response.brand.id;
        if(response.brand.id){this.getCategories(); }
        
      })
      
      
    })
  }
  getCategories(page=1){
    this.brandService.getCategoriesForBrand(this.brand_id,page).subscribe((resp:any) =>{
      this.categories=resp.categories;
      this.dataLoaded=true;
    })
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.getCategories(this.currentPage);
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getCategories(this.currentPage);
  }
}
