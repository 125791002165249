<main class="bg-gray-100 py-3">
   <!-- Hiển thị skeleton-box nếu đang tải -->
   <div *ngIf="!dataLoaded; else content" class="container">
      <div class="skeleton-box mb-3" style="height: 40px;"></div>
      <div class="grid_container1">
         <div *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8,9,10]" class="columns-md-2 columns-xs-6 skeleton-box" style="height: 300px"></div>
      </div>
   </div>
 
   <!-- Hiển thị nội dung thực tế khi tải xong -->
   <ng-template #content>
      <div class="container">
         <section class="breadcrumb__area include-bg mb-3">
            <div class="breadcrumb__content position-relative z-index-1">
               <div class="breadcrumb__list mb-3">
                  <span><a routerLink="/{{currentLanguage}}">{{'trangchu' | translate}}</a></span>
                  <span>{{ brand.name }}</span>
               </div>
               <h1 class="breadcrumb__title">{{brand.name}}</h1>
            </div>
         </section>
         <div class="grid_container1">
            <div class="columns-md-2 columns-sm-3 columns-xs-6 item_product bg-white" *ngFor="let category of categories">
               <div class="images">
                  <a routerLink="/{{currentLanguage}}/{{ category.slug }}/{{ brand.slug }}">
                     <img *ngIf="category.image; else usePhoto" appLazyLoad="{{ category.image.thumbnail }}" src="assets/images/400x400.webp" alt="{{category.name}}" class="img-fluid" loading="lazy" onerror="this.onerror=null;this.src='assets/images/no-image.jpg';" width="300px" height="300px">
                     <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                     <ng-template #usePhoto>
                     <img src="assets/images/no-image.jpg" alt="{{category.name}}" class="img-fluid" width="300px" height="300px">
                     </ng-template>
                  </a>
               </div>
               <div class="info p-4 py-2">
                  <a routerLink="/{{currentLanguage}}/{{ category.slug }}/{{ brand.slug }}">
                     <h3>{{ category.name }}</h3>
                  </a>
               </div>
            </div>
         </div>
         <ngb-pagination *ngIf="totalPages/20>1"
            [collectionSize]="totalPages"
            [(page)]="currentPage"
            [pageSize]="20"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
            [maxSize]="5"
         ></ngb-pagination>
         <div class="contentDetail mh-200 bg-white p-3 my-3" *ngIf="brand.content" [ngStyle]="{'height': isContentExpanded ? 'auto' : '200px'}">
            <div class="content" [innerHTML]="brand.content"></div>
            <div class="viewMore">
                  <a class="btn btn_readmore ripple-surface" (click)="showMore()" [class.active]="isContentExpanded"><span>{{ isContentExpanded ? 'Rút gọn' : 'Xem thêm' }}</span> <svg class="svg svg-more-down ml-3" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"><path fill="none" fill-rule="evenodd" stroke="#08711c" stroke-linecap="round" stroke-width="2" d="M7.5 1.5l-3 3-3-3"></path></svg></a>
            </div>
         </div>
      </div>
   </ng-template>
</main>