import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Base } from 'src/app/shared/base';
import { HomeService } from './service/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  homeData: any;
  categories: any[] = [];
  productSale: any[] = [];
  slider: any[] = [];
  why: any[] = [];
  settings: any = null;
  schema: string = '';
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  isLoading: boolean = true;

  constructor(
    public languageService: LanguageService,
    public settingsService: SettingsService,
    private homeService: HomeService,
  ) {}

  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.homeService.home().subscribe((resp:any)=>{
      this.slider = resp.sliders;
      this.categories = resp.categories;
      this.productSale = resp.productSale;
      this.why = resp.why;
      this.isLoading=false;
    })
  }
}
