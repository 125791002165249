import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../service/product.service';
import { Base } from 'src/app/shared/base';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.css'
})
export class CommentComponent extends Base {
  @Input() product_id: any;
  comments:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  stars: number[] = Array(5).fill(0);
  comment:any={
    name:'',
    email:'',
    content:'',
    rate:5,
    product_id:0
  };
  constructor(
    public productService: ProductService,
    public toastr: ToastrService,
    languageService: LanguageService
  ){
    super(languageService);
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.comment.product_id=this.product_id;
    this.getComments();
  }
  getComments(page=1){
    this.productService.getcomments(this.product_id, page).subscribe((resp:any) =>{
      this.comments=resp.comments;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage(page: number): void {
    this.currentPage = page;
    this.getComments(page);
  }
  starsRate(star:number){this.comment.rate=star;}
  save(){
    if(this.comment.name==''){
      this.toastr.error("Vui lòng nhập họ tên của bạn");
      return;
    }
    if(this.comment.email==''){
      this.toastr.error("Vui lòng nhập email của bạn");
      return;
    }
    if(this.comment.content==''){
      this.toastr.error("Vui lòng nhập nội dung bạn muốn nhắn gửi");
      return;
    }
    this.productService.addComment(this.comment).subscribe((resp:any) =>{
      this.toastr.success("Cảm ơn bạn đã đánh giá sản phẩm");
    }, (error)=>{this.toastr.error(error.error.message);})
  }
}
