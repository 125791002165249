import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListNewsComponent } from './list-news/list-news.component';
import { DetailNewsComponent } from './detail-news/detail-news.component';
import { CategoriesNewsComponent } from './categories-news/categories-news.component';
import { NewsComponent } from './news.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLazyloadModule } from '../general/images-lazyload/images-lazyload.module';
import { ArticleModule } from '../article/article.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ListNewsComponent,
    DetailNewsComponent,
    CategoriesNewsComponent,
    NewsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    NgbPaginationModule,
    ImagesLazyloadModule,
    ArticleModule,
    TranslateModule
  ]
})
export class NewsModule { }
