import { NgModule } from '@angular/core';
import { Routes, RouterModule,PreloadAllModules  } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ArticleComponent } from './components/article/article.component';
import { ListArticleComponent } from './components/article/list-article/list-article.component';
import { DetailArticleComponent } from './components/article/detail-article/detail-article.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { ChangePassComponent } from './components/auth/change-pass/change-pass.component';
import { HistoryOrderComponent } from './components/auth/history-order/history-order.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OrderConfirmComponent } from './components/order-confirm/order-confirm.component';
import { OrderComponent } from './components/order/order.component';
import { ContentArticleComponent } from './components/content-article/content-article.component';
import { BrandComponent } from './components/brand/brand.component';
import { ListBrandComponent } from './components/brand/list-brand/list-brand.component';
import { DetailBrandComponent } from './components/brand/detail-brand/detail-brand.component';
import { ContactComponent } from './components/contact/contact.component';
import { ListNewsComponent } from './components/news/list-news/list-news.component';
import { CategoriesNewsComponent } from './components/news/categories-news/categories-news.component';
import { DetailNewsComponent } from './components/news/detail-news/detail-news.component';
import { NewsComponent } from './components/news/news.component';
import { brandUrlMatcher, categoryUrlMatcher, newsCategoryUrlMatcher, newsDetailUrlMatcher, productUrlMatcher } from './custom-url-matcher';
import { BrandCategorieComponent } from './components/product/brand-categorie/brand-categorie.component';
import { CategoriesComponent } from './components/product/categories/categories.component';
import { DetailComponent } from './components/product/detail/detail.component';
import { ListProductComponent } from './components/product/list-product/list-product.component';
import { ProductComponent } from './components/product/product.component';
import { SearchComponent } from './components/search/search.component';
import { HomeResolver } from './components/home/home.resolver';
import { leftResolver } from './components/resolver/left.resolver';
import { TitleResolver } from './components/resolver/title.resolver';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { SitemapResolver } from './components/resolver/sitemap-resolver.resolver';

const routes: Routes = [
    {
        path: 'sitemap.xml',
        component: SitemapComponent,
        resolve: { data: SitemapResolver },
      },
    {path: ':language?',
        component: HomeComponent,
    },
    {
        path: ':language?/login',
        component:LoginComponent,
    },
    {
        path: ':language?/logout',
        component:LogoutComponent,
    },
    {
      path: ':language?/register',
      component:RegisterComponent,
    },
    {
      path: ':language?/forgot-password',
      component:ForgotPasswordComponent,
    },{
      path: ':language?/profile',
      component:ProfileComponent,
    },{
      path: ':language?/lich-su-mua-hang',
      component:HistoryOrderComponent,
    },{
      path: ':language?/change-pass',
      component:ChangePassComponent,
    },{
      path: ':language?/cart',
      component:CartComponent,
    },{
      path: ':language?/checkout',
      component: CheckoutComponent,
    },{
      path: ':language?/order-confirmation/:orderID',
      component: OrderConfirmComponent,
    },{
      path: ':language?/tra-cuu-don-hang',
      component:OrderComponent,
    },{
        path: ':language?/lien-he',
        component:ContactComponent,
    },{
        path: ':language?/chinh-sach-bao-mat',
        component:ContentArticleComponent,
        data: { type: 'baomat', title:'chinhsachbaomat',com:'chinh-sach-bao-mat' },
        resolve: {
            title: TitleResolver,
        },
    },{
        path: ':language?/dieu-khoan',
        component:ContentArticleComponent,
        data: { type: 'policy', title:'dieukhoandieukien',com:'dieu-khoan' },
        resolve: {
            title: TitleResolver,
        },
    },{
        path: ':language?/thuong-hieu',
        component: BrandComponent,
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListBrandComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailBrandComponent,
            }
        ],
        resolve: {
            //leftData: leftResolver, title: TitleResolver
        }
    },
    {
        path: ':language?/gioi-thieu',
        component:ArticleComponent,
        data: { type: 'about', title:'gioithieu',com:'gioi-thieu' },
        children:[
            {
                path: '', 
                component: ListArticleComponent,
            },{
                path: ':slug',
                component: DetailArticleComponent,
                data: { type: 'about', title:'gioithieu',com:'gioi-thieu' },
            }
        ],
        resolve: {
            leftData: leftResolver,
            title: TitleResolver
        }
    },{
        path: ':language?/tuyen-dung',
        component:ArticleComponent,
        data: { type: 'tuyendung', title:'tuyendung',com:'tuyen-dung' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },{
        path: ':language?/faqs',
        component:ArticleComponent,
        data: { type: 'faqs', title:'cauhoithuonggap',com:'faqs' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },{
        path: ':language?/ho-tro',
        component:ArticleComponent,
        data: { type: 'support', title:'chinhsachhotro',com:'ho-tro' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },
    {
        path: ':language?/giai-phap', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'giaiphap', title:'giaiphap',com:'giai-phap' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'giaiphap', title:'giaiphap',com:'giai-phap' },
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },{
        path: ':language?/tin-tuc', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'news', title:'tintuc',com:'tin-tuc' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'news', title:'tintuc',com:'tin-tuc' },
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },{
        path: ':language?/dich-vu', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'service', title:'dichvu',com:'dich-vu' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'service', title:'dichvu',com:'dich-vu' },
            }
        ],
        resolve: {
            leftData: leftResolver, title: TitleResolver
        }
    },
    {
        path: ':language?/tim-kiem/:keyword',
        component:SearchComponent,
        data: { type: 'about' }
    },
    // {
    //     path: ':language?/:slug-c:id',
    //     component: CategoriesComponent,
    // },
    
    {
        path: ':language?/san-pham',
        component: ProductComponent,
        children: [
            {
                path: '',
                component: ListProductComponent,
            },{
            path: ':language?/:categorySlug',
            component: CategoriesComponent,
            },
            // {
            //     path: ':language?/:categorySlug/:productSlug',
            //     component: DetailComponent,
            // }
        ],
        resolve: {
            //title: TitleResolver,
            leftData: leftResolver
        }
    },{ path: ':language?/404', component: NotFoundComponent },
    // {
    // matcher: productUrlMatcher,
    //     component: DetailComponent,
    // },
    {
    matcher: newsDetailUrlMatcher,
        component: DetailNewsComponent,
    },
    {
        path: ':language?/:slugCategory/:slug-tjk:id', // Route cho sản phẩm với slug và id
        component: DetailComponent, // Component sẽ hiển thị chi tiết sản phẩm
    },
    {
    matcher: categoryUrlMatcher,
        component: CategoriesComponent,
        resolve: {
            //title: TitleResolver,
            leftData: leftResolver
        }
    },
    {
    matcher: brandUrlMatcher,
        component: BrandCategorieComponent,
    },
    {
    matcher: newsCategoryUrlMatcher,
        component: CategoriesNewsComponent,
        resolve: {
            //title: TitleResolver,
            leftData: leftResolver
        }
    },
  { path: ':language?/**', redirectTo: ':language?/404' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
