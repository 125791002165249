import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { URL_FRONTEND } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from '../service/product.service';
import { Base } from 'src/app/shared/base';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';
declare var $:any;
declare function detailProduct($: any): void;
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.css'
})

export class DetailComponent {
  settings:any=[];
  PRODUCT_SLUG:any;
  PRODUCT_ID:any;
  PRODUCT_SELECTED:any;
  attributes:any;
  variations:any;
  variation_selected:any;
  selectedProperties: { [key: number]: number } = {};
  sub_variation_selected:any;
  PRODUCT_RELATEDS:any = [];
  isNotFound: boolean = false;
  URL_FRONTEND:string =URL_FRONTEND;
  quantity: number = 1;
  selectedImageIndex: number = 0;
  price:number=0;
  priceSale:number=0;
  sku:string='';
  safeVideoUrls: SafeResourceUrl[] = [];
  title:string='';
  description:string='';
  imageUrl:string='';

  apiLoaded = false;
  height = '100%';
  width = '100%';

  startSeconds = 60;
  endSeconds = 120;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  constructor(
    public productService: ProductService,
    public activedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
    private languageService: LanguageService,
    private settingsService: SettingsService
  ){}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.activedRoute.params.subscribe((resp:any) => {
      const slug = resp["slug-tjk:id"];
      const parts = slug ? slug.match(/(.*?)-tjk(\d+)$/) : null;
      
      if (parts && parts.length > 2) {
        this.PRODUCT_SLUG = parts[1];
        this.PRODUCT_ID = parts[2];

        this.getProductDetail();
      } else {
        this.isNotFound = true;
        this.settingsService.updateMetaTags404("404 page not found", "", "", "");
        console.error("Invalid slug format:", slug);
      }
    })
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.apiLoaded) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);
        this.apiLoaded = true;
      }
    }
  }
  getYoutubeEmbedUrl(videoUrl: string): SafeResourceUrl {
    const videoId = this.extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${videoId}?feature=oembed`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  extractVideoId(url: string): string {
    const videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      return videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }
  getProductDetail(){
    this.productService.showProduct(this.PRODUCT_SLUG, this.PRODUCT_ID).subscribe((resp:any) => {
      if(resp.message == 403){
        //this.toastr.error("Lỗi",resp.message_text);
        this.isNotFound = true;
        this.settingsService.updateMetaTags404("404 page not found", "", "", "");
      }else{
        this.PRODUCT_SELECTED = resp.product;
        this.price = resp.product.price;
        this.priceSale = resp.product.priceSale;
        this.sku = resp.product.sku;
        this.attributes = resp.attributes;
        this.variations = resp.variations;
        this.PRODUCT_RELATEDS = resp.product_relateds;
        this.variation_selected=null;
      }
    },(error)=>{
      this.isNotFound = true;
      this.settingsService.updateMetaTags404("404 page not found", "", "", "");
    })
    
  }
  
  selectedVariation(attributeId: number, property: any) {
    // Cập nhật thuộc tính đã chọn
    if (this.selectedProperties[attributeId] === property.id) {
      delete this.selectedProperties[attributeId]; // Bỏ chọn thuộc tính
    } else {
      // Cập nhật thuộc tính đã chọn
      this.selectedProperties[attributeId] = property.id;
    }

    this.updateAvailableProperties();
    
    // Kiểm tra số lượng thuộc tính đã chọn trước khi tìm variation
    this.findMatchingVariation();
  }
  // Cập nhật trạng thái của các thuộc tính dựa trên thuộc tính đã chọn
  updateAvailableProperties() {
    const selectedAttributes = Object.keys(this.selectedProperties);
    
    // Nếu chỉ có 1 attribute
    if (this.attributes.length === 1) {
      // Lấy thuộc tính duy nhất
      const attribute = this.attributes[0];
      
      // Duyệt qua các property của attribute duy nhất
      attribute.properties.forEach((property: any) => {
        // Kiểm tra nếu thuộc tính hiện tại đã được chọn
        const isPropertySelected = this.isPropertySelected(attribute.id, property.id);
  
        let isAvailable = false;
  
        if (isPropertySelected) {
          // Nếu thuộc tính đã được chọn, không disable các thuộc tính cùng loại
          isAvailable = true;
        } else {
          // Không disable các property có stock > 0
          isAvailable = this.variations.some((variation: any) => {
            // Kiểm tra thuộc tính có stock > 0
            return variation.stock > 0 && variation.properties.some((prop: any) => prop.id === property.id);
          });
        }
  
        // Cập nhật trạng thái available cho thuộc tính
        property.available = isAvailable;
      });
    } 
    // Trường hợp có nhiều attribute
    else {
      this.attributes.forEach((attribute: any) => {
        attribute.properties.forEach((property: any) => {
          let isAvailable = false;
    
          // Kiểm tra nếu thuộc tính hiện tại đã được chọn
          const isPropertySelected = this.isPropertySelected(attribute.id, property.id);
    
          if (isPropertySelected) {
            // Nếu thuộc tính đã được chọn, không disable các thuộc tính cùng loại
            isAvailable = true;
          } else {
            // Chỉ disable thuộc tính dựa trên stock và biến thể hợp lệ
            isAvailable = this.variations.some((variation: any) => {
              // Kiểm tra nếu biến thể khớp với các thuộc tính đã chọn
              const matchesSelected = this.isVariationMatchingSelected(variation);
    
              // Kiểm tra thuộc tính trong biến thể và stock > 0
              return matchesSelected && variation.stock > 0 && variation.properties.some((prop: any) => prop.id === property.id);
            });
          }
    
          // Cập nhật trạng thái available cho thuộc tính
          property.available = isAvailable;
        });
      });
    }
  }
  
  // Kiểm tra xem biến thể có khớp với các thuộc tính đã chọn không
  isVariationMatchingSelected(variation: any) {
    return Object.entries(this.selectedProperties).every(([attributeId, propertyId]) => {
      return variation.properties.some(
        (prop: any) => prop.attribute.id === +attributeId && prop.id === +propertyId
      );
    });
  }
  
  // Kiểm tra thuộc tính hiện tại có được chọn không
  isPropertySelected(attributeId: number, propertyId: number): boolean {
    return this.selectedProperties[attributeId] === propertyId;
  }
  
  // Tìm biến thể phù hợp dựa trên thuộc tính đã chọn
  findMatchingVariation() {
    const selectedAttributes = Object.entries(this.selectedProperties);
  
    if (selectedAttributes.length === this.attributes.length) {
      this.variation_selected = this.variations.find((variation: any) => {
        this.price = variation.price;
        this.priceSale = variation.priceSale;
        return selectedAttributes.every(([attributeId, propertyId]) => {
          return variation.properties.some(
            (prop: any) => prop.attribute.id === +attributeId && prop.id === +propertyId
          );
        });
      });
    } else {
      this.variation_selected = null;
    }
  }

  decrementQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  // Phương thức tăng số lượng
  incrementQuantity() {
    this.quantity++;
  }
  trackById(index: number, item: any): any {
      return item.id;
  }
  buynow(){
    const check = this.addCart();
    if(check==true) this.router.navigate(['/cart']);
  }

  getSelectedVariations() {
    let selectedVariations = [];
  
    // Kiểm tra nếu có biến thể chính được chọn
    if (this.variation_selected) {
      selectedVariations.push({
        id: this.variation_selected.id,
        name_attribute: this.variation_selected.attribute.name,
        attribute_id: this.variation_selected.attribute_id,
        name_property: this.variation_selected.propertie ? this.variation_selected.propertie.name : this.variation_selected.value_add,
        id_property: this.variation_selected.propertie_id,
        price_add: this.variation_selected.add_price,
        //value_add: this.variation_selected.value_add
      });
  
      // Kiểm tra nếu có biến thể phụ được chọn
      if (this.sub_variation_selected) {
        selectedVariations.push({
          id: this.sub_variation_selected.id,
          name_attribute: this.sub_variation_selected.attribute.name,
          attribute_id: this.sub_variation_selected.attribute_id,
          name_property: this.sub_variation_selected.propertie ? this.sub_variation_selected.propertie.name : this.sub_variation_selected.value_add,
          id_property: this.sub_variation_selected.propertie_id,
          price_add: this.sub_variation_selected.add_price,
          //value_add: this.sub_variation_selected.value_add
        });
      }
    }
  
    return selectedVariations;
  }
  addCart(){
    // if (this.variations.length>0 && !this.variation_selected) {
    //   this.toastr.error("Lỗi","Bạn cần chọn một biến thể");
    //     return false;
    // }
    // Kiểm tra xem stock của biến thể được chọn có bằng 0 không
    // if (this.variation_selected && this.variation_selected.stock === 0) {
    //   this.toastr.error("Thông báo", "Sản phẩm bạn chọn hiện đã hết hàng.");
    //   return false;
    // }
    if (this.PRODUCT_SELECTED.stock === 0) {
      this.toastr.error("Thông báo", "Sản phẩm bạn chọn hiện đã hết hàng.");
      return false;
    }
    this.cartService.addToCart({
      productId: this.PRODUCT_SELECTED.id,
      variationId: (this.variation_selected) ? this.variation_selected.id : null,
      variation: this.variation_selected,
      quantity: this.quantity,
      //price: (this.variation_selected.price) ? this.variation_selected.price : this.PRODUCT_SELECTED.price,
      price: (this.variation_selected) ? this.variation_selected.priceSale : this.PRODUCT_SELECTED.priceSale,
      name: this.PRODUCT_SELECTED.name,
      sku: this.PRODUCT_SELECTED.sku,
      photo: (this.PRODUCT_SELECTED.image) ? this.PRODUCT_SELECTED.image.thumbnail : ''
      //photo: (this.variation_selected && this.variation_selected.image) ? this.variation_selected.image : this.PRODUCT_SELECTED.image.thumbnail
    });
    //console.log(this.cartService.getCartItems());
    this.toastr.success("Thông báo","Đã thêm sản phẩm vào giỏ hàng.");
    return true;
  }
  getTotalPriceProduct(PRODUCT:any){
    var price=PRODUCT.priceSale;
    if(this.variation_selected){
      price=price*1+this.variation_selected.add_price*1;
    }
    if(this.sub_variation_selected){
      price=price*1+this.sub_variation_selected.add_price*1;
    }
    return price;
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
}
