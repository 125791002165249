import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiLoadServiceService {
  private isBaseApisLoaded = false;
  constructor() { }

  setBaseApisLoaded() {
    this.isBaseApisLoaded = true;
  }

  getBaseApisLoaded() {
    return this.isBaseApisLoaded;
  }
  
}
