import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './components/home/home.module';
import { GeneralModule } from './components/general/general.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ArticleModule } from './components/article/article.module';
import { ProductModule } from './components/product/product.module';
import { ToastrModule } from 'ngx-toastr';
import { ContactModule } from './components/contact/contact.module';
import { BrandModule } from './components/brand/brand.module';
import { NewsModule } from './components/news/news.module';
import { CartModule } from './components/cart/cart.module';
import { CheckoutModule } from './components/checkout/checkout.module';
import { OrderModule } from './components/order/order.module';
import { OrderConfirmModule } from './components/order-confirm/order-confirm.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { NotFoundModule } from './components/not-found/not-found.module';
import { SearchModule } from './components/search/search.module';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    /* ArchiveComponent */
  ],
  imports: [
    BrowserAnimationsModule,

    HomeModule,
    GeneralModule,
    ArticleModule,
    ProductModule,
    ContactModule,
    BrandModule,
    NewsModule,
    CartModule,
    CheckoutModule,
    OrderModule,
    OrderConfirmModule,
    NotFoundModule,
    SearchModule,

    // AnimateOnScrollModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    ToastrModule.forRoot()
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
