import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_SERVICEIO } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  constructor(private http: HttpClient) { }
  getPages(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/pages');
  }

  getBlogs(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/blogs');
  }
  getNewsCates(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/newsCate');
  }
  getNews(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/news');
  }

  getCategoriesProducts(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/categories-products');
  }

  getProducts(page: number = 1): Observable<any> {
    return this.http.get(URL_SERVICEIO+`/frontEnd/sitemap/products?page=${page}&limit=1000`);
  }

  getBrands(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/brands');
  }
  getSitemapData(): Observable<any> {
    return this.http.get(URL_SERVICEIO+'/frontEnd/sitemap/sitemapData');
  }
}
