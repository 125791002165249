<div class="filter-total mb-3">
    <div class="filter-item__title jsTitle" (click)="toggleFilter(1)">
      <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 1 }"></div>
      <i class="fas fa-filter"></i> {{"loc" | translate}} 
      <strong class="number count-total" [ngClass]="{ active: totalSearch > 0 }">{{ totalSearch }}</strong>
    </div>
   
     <div class="filter-show show-total" id="wrapper" [ngClass]="{ active: activeFilterIndex === 1 }">
        <div class="list-filter-active" [ngClass]="{'active': (filterBrand.length > 0 || filterOrigin.length > 0 || filterPrice.length > 0 || filterOption.length > 0)}">
           <b class="me-3">{{"dachon" | translate}}: </b>
            <div class="price d-inline">
              <ng-container *ngIf="filterPrice.length > 0">
                 <span *ngFor="let item of filterPrice">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'price')"></i>
                 </span>
              </ng-container>
            </div>
            <div class="price d-inline">
              <ng-container *ngIf="filterOption.length > 0">
                 <span *ngFor="let item of filterOption">
                    {{ item.name }} <i class="fal fa-times ms-2" (click)="removeFilter(item,'options')"></i>
                 </span>
              </ng-container>
            </div>
           <a href="javascript:;" class="clr-filter" (click)="clearFilter()" >{{"dachon" | translate}}</a>
        </div>
        <div class="show-total-main">
           <a href="javascript:;" class="close-popup-total" (click)="closeFilter()">
              <i class="far fa-times-circle me-1"></i> {{"dong" | translate}}
           </a>
           <div class="show-total-item count-item arranged">
              <div class="row">
                 <div class="col-md-4 col-12 mb-3">
                    <p class="show-total-txt">{{"gia" | translate}}</p>
                    <div class="filter-list price d-flex flex-wrap align-items-center">
                       <ng-container  *ngFor="let item of prices">
                          <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'price')" [ngClass]="{'active': isActive(item.id,'price')}" >{{item.name}} </a>
                       </ng-container>
                    </div>
                 </div>
                 <div class="col-md-4 col-12 mb-3">
                   <p class="show-total-txt">{{"xuatxu" | translate}}</p>
                   <div class="filter-list price d-flex flex-wrap align-items-center">
                      <ng-container  *ngFor="let item of origins">
                         <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'origin')" [ngClass]="{'active': isActive(item.id,'origin')}">{{item.name}} </a>
                      </ng-container>
                   </div>
                 </div>
                  <div class="col-md-4 col-12" *ngFor="let item of optionsSearch">
                     <p class="show-total-txt">{{ item.name }}</p>
                     <div class="filter-list price d-flex flex-wrap align-items-center">
                        <ng-container  *ngFor="let option of item.options">
                           <a class="c-btnbox " data-id="{{option.id}}" (click)="addFilter(option,'options')" [ngClass]="{'active': isActive(option.id,'options')}">{{option.name}} </a>
                        </ng-container>
                      </div>
                  </div>
              </div>
              
           </div>
           
           
           <div class="filter-border"></div>
           <div class="filter-button filter-button--total" style="display: block;">
              <a href="javascript:;" class="btn-filter-close" (click)="clearFilter()">{{"bochon" | translate}}</a>
              <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">{{"xemketqua" | translate}} </a>
           </div>
        </div>
     </div>
  </div>
      <div class="filter-item d-inline position-relative ms-2">
         <div class="filter-item__title" (click)="toggleFilter(3)">
            <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 3 }"></div>
            <span>Giá</span>
         </div>
         <div class="filter-option" [ngClass]="{ active: activeFilterIndex === 3 }">
            <p class="show-total-txt mt-3">Giá</p>
            <div class="filter-list price d-flex flex-wrap align-items-center">
               <ng-container  *ngFor="let item of prices">
                  <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'price')" [ngClass]="{'active': isActive(item.id,'price')}">{{item.name}} </a>
               </ng-container>
            </div>
            <div class="button-filter-area" *ngIf="filterPrice.length > 0">
               <div class="filter-button">
                  <a href="javascript:;" class="btn-filter-close" (click)="clearFilter()">{{"bochon" | translate}}</a>
                  <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">{{"xemketqua" | translate}} </a>
               </div>
            </div>
         </div>
      </div>
      <div class="filter-item d-inline position-relative ms-2">
       <div class="filter-item__title" (click)="toggleFilter(2)">
       <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === 2 }"></div>
       <span>{{"xuatxu" | translate}}</span>
       </div>
       <div class="filter-option" [ngClass]="{ active: activeFilterIndex === 2 }">
       <p class="show-total-txt mt-4">{{"xuatxu" | translate}}</p>
       <div class="filter-list price d-flex flex-wrap align-items-center">
          <ng-container  *ngFor="let item of origins">
             <a class="c-btnbox " data-id="{{item.id}}" (click)="addFilter(item,'origin')" [ngClass]="{'active': isActive(item.id,'origin')}">{{item.name}} </a>
          </ng-container>
       </div>
       <div class="button-filter-area" *ngIf="filterOrigin.length > 0">
             <div class="filter-button">
               <a href="javascript:;" class="btn-filter-close" (click)="clearFilter()">{{"bochon" | translate}}</a>
               <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">{{"xemketqua" | translate}} </a>
             </div>
          </div>
       </div>
    </div>
      <div class="filter-item d-inline position-relative ms-2" *ngFor="let item of optionsSearch; let i = index">
         <div class="filter-item__title" (click)="toggleFilter(i+4)">
            <div class="arrow-filter" [ngClass]="{ active: activeFilterIndex === i+4 }"></div>
            <span>{{ item.name }}</span>
         </div>
         <div class="filter-option" [ngClass]="{ active: activeFilterIndex === i+4 }">
            <p class="show-total-txt mt-3">{{ item.name }}</p>
            <div class="filter-list price d-flex flex-wrap align-items-center">
                  <ng-container  *ngFor="let option of item.options">
                     <a class="c-btnbox " data-id="{{option.id}}" (click)="addFilter(option,'options')" [ngClass]="{'active': isActive(option.id,'options')}">{{option.name}} </a>
                  </ng-container>
            </div>
            <div class="button-filter-area" *ngIf="filterOption.length > 0">
               <div class="filter-button">
                  <a href="javascript:;" class="btn-filter-close" (click)="clearFilter()">{{"bochon" | translate}}</a>
                  <a href="javascript:;" class="btn-filter-readmore" (click)="filterSearch()">{{"xemketqua" | translate}} </a>
               </div>
            </div>
         </div>
      </div>
      <div class="layer-search" *ngIf="activeFilterIndex==1" (click)="activeFilterIndex = null"></div>