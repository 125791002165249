<main>
    <section class="breadcrumb__area include-bg py-5">
        <div class="container">
           <div class="row">
              <div class="col-xxl-12">
                 <div class="breadcrumb__content position-relative z-index-1">
                    <h3 class="breadcrumb__title">{{"giohang" | translate}}</h3>
                    <div class="breadcrumb__list">
                       <span><a routerLink="/{{currentLanguage}}">{{"trangchu" | translate}}</a></span>
                       <span>{{"giohang" | translate}}</span>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
     <!-- cart area start -->
     <section class="tp-cart-area pb-5">
        <div class="container">
            <div *ngIf="cartItems.length === 0">
                <p>{{"giohangtrong" | translate}}</p>
            </div>
            <div class="row" *ngIf="cartItems.length > 0">
              <div class="col-xl-9 col-lg-8">
                 <div class="tp-cart-list mb-4 me-3">
                    <table class="table table-bordered">
                       <thead>
                         <tr>
                           <th colspan="2" class="tp-cart-header-product">{{"sanpham" | translate}}</th>
                           <th class="tp-cart-header-price">{{"gia" | translate}}</th>
                           <th class="tp-cart-header-quantity">{{"soluong" | translate}}</th>
                           <th></th>
                         </tr>
                       </thead>
                       <tbody>
                          <tr *ngFor="let item of cartItems">
                             <!-- img -->
                             <td class="tp-cart-img">
                                 <img *ngIf="item.photo; else usePhoto" appLazyLoad="{{ item.photo }}" src="assets/images/400x400.png" alt="{{ item.name }}" class="img-fluid" loading="lazy"  onerror="this.onerror=null;this.src='assets/images/no-image.jpg';">
                                 <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                                 <ng-template #usePhoto>
                                 <img src="assets/images/no-image.jpg" alt="{{ item.name }}" class="img-fluid">
                                 </ng-template>
                              </td>
                             <!-- title -->
                             <td class="tp-cart-title px-2">
                                {{ item.name }}

                                <div *ngIf="item.variation">
                                    <div *ngFor="let variation of item.variation.properties">
                                    <b>{{ variation.attribute.name }}</b>: {{ variation.name }}
                                    </div>
                                </div>
                             </td>
                             <!-- price -->
                             <td class="tp-cart-price"><span>{{ item.price | number:'1.0-0' }} đ</span></td>
                             <!-- quantity -->
                             <td class="tp-cart-quantity">
                                <div class="tp-product-quantity mt-2 mb-2">
                                   <input class="form-control" type="number" [(ngModel)]="item.quantity" (change)="updateQuantity(item)" min="1">
                                </div>
                             </td>
                             <!-- action -->
                             <td class="tp-cart-action">
                                <button class="btn tp-cart-action-btn btn-danger" (click)="removeFromCart(item.productId, item.variationId)">
                                   <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 1.53033C9.82322 1.23744 9.82322 0.762563 9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967L5 3.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L3.93934 5L0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L5 6.06066L8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L6.06066 5L9.53033 1.53033Z" fill="currentColor"/>
                                   </svg>
                                   <span class="d-inline ms-1"> {{"xoa" | translate}}</span>
                                </button>
                             </td>
                          </tr>
                       </tbody>
                     </table>
                 </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                 <div class="tp-cart-checkout-wrapper">
                    <div class="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                       <span>{{"tongtien" | translate}}</span>
                       <span>{{ getTotalPrice() | number:'1.0-0' }} đ</span>
                    </div>
                    <div class="tp-cart-checkout-proceed mb-3">
                       <a routerLink="/{{currentLanguage}}/checkout" class="tp-cart-checkout-btn w-100 btn">{{"thanhtoan" | translate}}</a>
                    </div>
                    <div class="tp-cart-checkout-proceed mb-3">
                        <a routerLink="/{{currentLanguage}}/san-pham" class="tp-cart-checkout-btn w-100 btn btn-primary">{{"tieptucmuahang" | translate}}</a>
                     </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
     <!-- cart area end -->
</main>