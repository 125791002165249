<div class="container">
    <div class="group_product mb-5 bg-white p-3" *ngFor="let categorie of categories">
        <h2 class="title_page mt-3" data-aos="fade-up" data-aos-duration="800">{{ categorie.name }}</h2>
        <div class="grid_container1">
           <div class="columns-md-2 columns-xs-6 item_product" *ngFor="let product of categorie.products, let i=index" data-aos="fade-up" data-aos-duration="800" data="{i}" >
              <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
              <div class="images">
                 <a routerLink="/{{currentLanguage}}/{{ categorie.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                    <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.webp" alt="{{product.name}}" class="img-fluid" loading="lazy" onerror="this.onerror=null;this.src='assets/images/no-image.jpg';" width="300px" height="300px">
                    
                    <ng-template #usePhoto>
                    <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid" loading="lazy" width="300px" height="300px">
                    </ng-template>
                 </a>
              </div>
              <div class="info p-4 py-2">
                 <div class="tp-product-category"><a routerLink="/{{currentLanguage}}/{{ categorie.slug }}-c{{ categorie.id }}">{{ categorie.name }}</a></div>
                 <a routerLink="/{{currentLanguage}}/{{ categorie.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                    <h3>{{ product.name }}</h3>
                 </a>
                 <div class="tp-product-rating-icon">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                 </div>
                 <div class="price">
                    <span *ngIf="product.price" class="me-2">{{ product.price | number:'1.0-0' }} đ</span>
                    <strong *ngIf="product.priceSale > 0">
                       {{ product.priceSale | number:'1.0-0' }} đ
                    </strong>
                    <strong *ngIf="product.priceSale <= 0">
                       {{"lienhe" | translate}}
                    </strong>
                 </div>
              </div>
           </div>
        </div>
        <div class="text-center my-3" data-aos="fade-up" data-aos-duration="800">
           <a routerLink="/{{currentLanguage}}/{{ categorie.slug }}-c{{ categorie.id }}" class="readMore">{{"xemthem" | translate}} <span>
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 7.5L1 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                </span>
            </a>
        </div>
     </div>

     <div class="uudai bg-white my-3 p-3">
        <div class="grid_container">
           <div class="columns-md-2 columns-xs-6 itemUudai"  data-aos="fade-up" data-aos-duration="400" *ngFor="let item of why">
              <img [alt]="item.name" class="img-fluid lazyload" width="60px" height="60px" [src]="item.image.photo" style="display: inline;">
              <div class="name">{{item.name}}</div>
              <p>{{item.sabo}}</p>
           </div>
        </div>
     </div>
</div>