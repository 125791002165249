import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { URL_WEBSITE } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrl: './order-confirm.component.css'
})
export class OrderConfirmComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  orderID:string='';
  order:any=[];
  orderDetail:any=[];
  title:string='';
  settings:any=[];
  orderInfoVisible: boolean = false;
  message:string='';
  constructor(
    private cartService: CartService,
    public router: Router,
    public activedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    private toastr: ToastrService,
    public languageService: LanguageService,
    private translate: TranslateService,
  ) {
    this.activedRoute.params.subscribe((resp:any) => {
      this.orderID = resp.orderID;
      this.title="Thông tin đơn hàng #"+this.orderID;
    })
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.settings$.subscribe(
      (data) => {
        if(data){
          this.settings = data;
          this.updateMetaTags();
        }
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    this.getOrder();
  }
  getPaymentText(): string {
    return this.order.payment === 'cod'
      ? this.translate.instant('thanhtoankhinhanhang')
      : this.translate.instant('thanhtoanchuyenkhoan');
  }
  getOrder(){
    this.cartService.getOrderCode(this.orderID).subscribe((resp:any) =>{
      if(resp.code==200){
        this.order=resp.order;
        this.orderDetail=resp.order_details;
        this.orderInfoVisible=true;
      }else{
        this.orderInfoVisible=false;
      }
    }, (error)=>{
      this.toastr.error("Lỗi", error.error.message);
      setTimeout(() => {
          this.router.navigateByUrl("/");
        }, 1000);
      return;
    })
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
