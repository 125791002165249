import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, RouterModule, TranslateModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  email: string="";
  password: string = "";
  code_user:string = "";
  isPasswordVisible: boolean = false;
  user: any;
  token: string | null = null;
  constructor(
    private toastr: ToastrService,
    private authService:AuthService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    public languageService: LanguageService,
  ){}
  ngOnInit():void{
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.authService.token$.subscribe((token) => {
      this.token = token;
    });
    
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
    if(this.token && this.user){
      setTimeout(() => {
        this.router.navigateByUrl("/"+this.currentLanguage+"/");
      }, 500);
      return;
    }
    this.activedRouter.queryParams.subscribe((resp:any)=>{
      this.code_user=resp.code;
    })

    if(this.code_user){
      let data={
        code: this.code_user,
      }
      this.authService.verifiedAuth(data).subscribe((resp: any)=>{
        if(resp.message==403){
          this.toastr.error("Thông báo", "Xác thực tài khoản chưa thành công.");
        }
        if(resp.message==200){
          this.toastr.success("Thông báo", "Xác thực tài khoản thành công.");
          setTimeout(() => {
            this.router.navigateByUrl("/"+this.currentLanguage+"/login");
          }, 500);
        }
        
      })
    }

    if (isPlatformBrowser(this.platformId)) {
      const btn = document.getElementById('password-show-toggle');
      const inputType = document.getElementById('tp_password') as HTMLInputElement;
      const openEye = document.getElementById('open-eye');
      const closeEye = document.getElementById('close-eye');
      

      if (btn && openEye && closeEye) {
        btn.addEventListener('click', () => {
          this.togglePasswordVisibility(inputType,openEye , closeEye);
        });
      }
    }
  }
  login(){
    if(!this.email || !this.password){
      this.toastr.error("Lỗi", "Vui lòng nhập thông tin đăng nhập");
      return;
    }
    this.authService.login(this.email, this.password).subscribe((resp:any) => {
      if(resp.error && resp.error.error){
        this.toastr.error("Lỗi", resp.error.error);
      }
      if(resp.error && resp.error.password){
        this.toastr.error("Lỗi", resp.error.password);
      }
      if(resp==true){
        this.toastr.success("Thông báo", "Đăng nhập thành công");
        setTimeout(() => {
          this.router.navigateByUrl("/"+this.currentLanguage);
        }, 500);
      }
    }, (error)=>{
      this.toastr.error("Lỗi", error.error);
      console.log(error);
    })
  }
  showSuccess() {
    this.toastr.success('Hello world!', 'Toastr fun!');
  }
  togglePasswordVisibility(inputType: HTMLInputElement, openEye: HTMLElement, closeEye: HTMLElement): void {
    if (inputType.type === 'password') {
      inputType.type = 'text';
      openEye.style.display = 'block';
      closeEye.style.display = 'none';
    } else {
      inputType.type = 'password';
      openEye.style.display = 'none';
      closeEye.style.display = 'block';
    }
  }
}
