import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SliderComponent } from '../general/slider/slider.component';
import { ProductSaleComponent } from './product-sale/product-sale.component';
import { ImagesLazyloadModule } from '../general/images-lazyload/images-lazyload.module';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    HomeComponent,
    SliderComponent,
    ProductSaleComponent,
    ProductCategoryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbNavModule,
    CarouselModule,
    ImagesLazyloadModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ]
})
export class HomeModule { }
