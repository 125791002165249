<div class="container">
    <div class="productHot mb-5 bg-white p-3">
        <h2 class="title_page" data-aos="fade-up" data-aos-duration="1000">{{"sanphambanchay" | translate}}</h2>
        <owl-carousel-o class='owl-carousel-o' [options]="customOptions" data-aos="fade-up" data-aos-duration="1000">
            <ng-container *ngFor="let product of productSale">
                <ng-template carouselSlide [id]="product.id">
                    <div class="item_product">
                        <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                        <div class="images">
                           <a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                              <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.webp" alt="{{product.name}}" class="img-fluid" loading="lazy" onerror="this.onerror=null;this.src='assets/images/no-image.jpg';" width="300px" height="300px">
                              <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                              <ng-template #usePhoto>
                              <img data-src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid" loading="lazy" width="300px" height="300px">
                              </ng-template>
                           </a>
                        </div>
                        <div class="info p-4 py-2">
                           <div class="tp-product-category"><a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                           <a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                           <div class="tp-product-rating-icon">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                           </div>
                           <div class="price">
                              <span *ngIf="product.price" class="me-2">{{ product.price | number:'1.0-0' }} đ</span>
                              <strong *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                              </strong>
                              <strong *ngIf="product.priceSale <= 0">
                                 {{"lienhe" | translate}}
                              </strong>
                           </div>
                        </div>
                      </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
     </div>
</div>