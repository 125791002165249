import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandComponent } from './brand.component';
import { ListBrandComponent } from './list-brand/list-brand.component';
import { DetailBrandComponent } from './detail-brand/detail-brand.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLazyloadModule } from '../general/images-lazyload/images-lazyload.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BrandComponent,
    ListBrandComponent,
    DetailBrandComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    NgbPaginationModule,
    ImagesLazyloadModule,
    TranslateModule
  ]
})
export class BrandModule { }
