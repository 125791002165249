<main>

    <!-- breadcrumb area start -->
    <section class="breadcrumb__area include-bg text-center py-5">
       <div class="container">
          <div class="row">
             <div class="col-xxl-12">
                <div class="breadcrumb__content p-relative z-index-1">
                   <h3 class="breadcrumb__title">Liên hệ với chúng tôi</h3>
                   <div class="breadcrumb__list">
                      <span><a routerLink="/">Trang chủ</a></span>
                      <span>Liên hệ</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- breadcrumb area end -->


    <!-- contact area start -->
    <section class="tp-contact-area pb-5">
       <div class="container">
          <div class="tp-contact-inner">
             <div class="row">
                <div class="col-12 col-lg-8">
                   <div class="tp-contact-wrapper">
                      <h3 class="tp-contact-title">Gửi tin nhắn cho chúng tôi</h3>

                      <div class="tp-contact-form">
                         <form [formGroup]="contactForm" id="contact-form" (ngSubmit)="send()">
                            <div class="tp-contact-input-wrapper">
                               <div class="tp-contact-input-box">
                                  <div class="tp-contact-input">
                                     <input name="name" class="form-control" id="name" type="text" placeholder="Họ và tên" formControlName="name">
                                  </div>
                                  <div class="tp-contact-input-title">
                                     <label for="name">Họ tên</label>
                                  </div>
                               </div>
                               <div class="tp-contact-input-box">
                                  <div class="tp-contact-input">
                                     <input name="phone" class="form-control" id="phone" type="tel" placeholder="Số điện thoại" formControlName="phone">
                                  </div>
                                  <div class="tp-contact-input-title">
                                     <label for="email">Số điện thoại</label>
                                  </div>
                               </div>
                               <div class="tp-contact-input-box">
                                 <div class="tp-contact-input">
                                    <input name="email" class="form-control" id="email" type="email" placeholder="Email" formControlName="email">
                                 </div>
                                 <div class="tp-contact-input-title">
                                    <label for="email">Email</label>
                                 </div>
                              </div>
                               <div class="tp-contact-input-box">
                                  <div class="tp-contact-input">
                                     <input name="subject" class="form-control" id="subject" type="text" placeholder="" formControlName="subject">
                                  </div>
                                  <div class="tp-contact-input-title">
                                     <label for="subject">Tiêu đề</label>
                                  </div>
                               </div>
                               <div class="tp-contact-input-box">
                                  <div class="tp-contact-input">
                                    <textarea id="message" name="message" class="form-control" placeholder="Nội dung muốn gửi" formControlName="message"></textarea>
                                  </div>
                                  <div class="tp-contact-input-title">
                                     <label for="message">Nội dung</label>
                                  </div>
                               </div>
                            </div>
                            <div >
                              <div *ngIf="isLoading" class="loading-indicator">
                                 Đang xử lý, vui lòng chờ...
                              </div>
                              <button type="submit" class="btn tp-contact-btn" [disabled]="isLoading">Gửi</button>
                            </div>
                         </form>
                         <p class="ajax-response"></p>
                      </div>
                   </div>
                </div>
                <div class="col-12 col-lg-4">
                   <div class="tp-contact-info-wrapper">
                      <div class="tp-contact-info-item d-flex align-items-center">
                         <div class="tp-contact-info-icon me-3">
                            <span>
                               <img src="assets/img/contact/contact-icon-1.png" alt="">
                            </span>
                         </div>
                         <div class="tp-contact-info-content">
                            <p data-info="mail"><a href="mailto:{{ settings?.email }}">{{ settings?.email }}</a></p>
                            <p data-info="phone"><a href="tel:{{ settings?.hotline }}">{{ settings?.hotline }}</a></p>
                         </div>
                      </div>
                      <div class="tp-contact-info-item d-flex align-items-center">
                         <div class="tp-contact-info-icon me-3">
                            <span>
                               <img src="assets/img/contact/contact-icon-2.png" alt="">
                            </span>
                         </div>
                         <div class="tp-contact-info-content">
                            <p>
                                {{ settings?.address }}
                            </p>
                         </div>
                      </div>
                      <div class="tp-contact-info-item d-flex align-items-center">
                         <div class="tp-contact-info-icon me-3">
                            <span>
                               <img src="assets/img/contact/contact-icon-3.png" alt="">
                            </span>
                         </div>
                         <div class="tp-contact-info-content">
                            <div class="tp-contact-social-wrapper">

                               <div class="tp-contact-social-icon">
                                <a *ngIf="settings" href="{{settings?.facebook}}" rel="nofollow" target="_blank" class="me-2"><i class="fab fa-facebook-f"></i></a>
                                <a *ngIf="settings" href="{{settings?.twitter}}" rel="nofollow" target="_blank" class="me-2"><i class="fab fa-twitter"></i></a>
                                <a *ngIf="settings" href="{{settings?.tiktok}}" rel="nofollow" target="_blank" class="me-2"><i class="fab fa-tiktok"></i></a>
                                <a *ngIf="settings" href="{{settings?.instagram}}" rel="nofollow" target="_blank" class="me-2"><i class="fab fa-instagram"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- contact area end -->

    <!-- map area start -->
    <section class="tp-map-area pb-5">
       <div class="container">
            <div class="tp-map-iframe" *ngIf="settings?.map" [innerHTML]="sanitizedIframe"></div>
       </div>
    </section>
    <!-- map area end -->

 </main>