import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TitleResolver implements Resolve<Promise<string>> {
  constructor(private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<string> {
    const key = route.data['title'];
    return this.translate.get(key).toPromise(); // Đợi hoàn tất dịch trước khi trả về
  }
}