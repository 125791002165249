<div *ngIf="dataLoaded" id="menuGroup" class="mt-3">
    <div class="container d-flex flex-wrap">
        <div class="col-md-3 col-12">
            <div class="productMenu d-md-block d-none">
                <div class="title"><i class="fas fa-bars"></i> {{"danhmucsanpham" | translate}}</div>
                <div class="cloudCategorie">
                    <div class="d-flex flex-wrap">
                        <div class="col-md-3 col-12">
                            <ul class="listCategories">
                                <!-- <li *ngFor="let item of categories" data-id="{{item.id}}" class="item" (mouseenter)="handleHover(item.id, true)"
                                (mouseleave)="handleHover(item.id, false)"><a routerLink="/{{currentLanguage}}/{{ item.slug}}-c{{ item.id }}/">{{ item.name }}</a></li> -->
                                <li *ngFor="let item of categories" data-id="{{item.id}}" class="item" (mouseenter)="handleHover(item.id)"><a routerLink="/{{currentLanguage}}/{{ item.slug}}-c{{ item.id }}/">{{ item.name }}</a></li>
                            </ul>
                        </div>
                        <div class="col-md-9 col-12">
                            <div *ngFor="let item of categories" class="subcategorie subcategorie_{{item.id}}">
                                <div *ngIf="item.children" >
                                    <div class="title_cate mb-3">{{ item.name }}</div>
                                    <div class="column d-flex flex-wrap">
                                        <ul class="col-md-3 col-12 pe-3" *ngFor="let column of [1, 2, 3, 4]">
                                            <ng-container  *ngFor="let subCategory of item.children">
                                                <li class="level-2-item" *ngIf="subCategory.position == column">
                                                    <a routerLink="/{{currentLanguage}}/{{ subCategory.slug}}-c{{ subCategory.id }}/">- {{ subCategory.name }}</a>
                                                    <ul class="level-3">
                                                        <li *ngFor="let thirdCate of subCategory.children">
                                                            <a routerLink="/{{currentLanguage}}/{{ thirdCate.slug}}-c{{ thirdCate.id }}/">{{ thirdCate.name }}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9 col-12">
            <nav #menu role="navigation" itemscope="" itemtype="https://schema.org/SiteNavigationElement" id="menu" [ngClass]="{'open': openNav}">
                <div class="close" (click)="closeMenu()"></div>
                <ul>
                    <li><a routerLink="/{{currentLanguage}}/">{{"trangchu" | translate}}</a></li>
                    <li><a routerLink="/{{currentLanguage}}/gioi-thieu">{{"gioithieu" | translate}}</a>
                        <ng-container *ngIf="abouts"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                        <ul>
                            <li *ngFor="let item of abouts"><a routerLink="/{{currentLanguage}}/gioi-thieu/{{ item.slug }}-p{{ item.id }}.html">{{ item.name }}</a></li>
                        </ul>
                    </li>
                    <li class="d-md-none d-block"><a routerLink="/{{currentLanguage}}/san-pham">{{"sanpham" | translate}}</a> 
                        <ng-container *ngIf="categories"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                        <ul>
                            <li *ngFor="let item of categories"><a routerLink="/{{currentLanguage}}/{{ item.slug}}-c{{ item.id }}">{{ item.name }}</a>
                                <ng-container *ngIf="item.categorie_second_child?.length > 0"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                                <ul *ngIf="item.categorie_second_child">
                                    <li *ngFor="let secondCate of item.categorie_second_child"><a routerLink="/{{currentLanguage}}/{{ secondCate.slug}}-c{{ secondCate.id }}">{{ secondCate.name }}</a>
                                        <ng-container *ngIf="secondCate.categorie_third_child?.length > 0"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                                        <ul *ngIf="secondCate.categorie_third_child">
                                            <li *ngFor="let thirdCate of secondCate.categorie_third_child"><a routerLink="/{{currentLanguage}}/{{ thirdCate.slug}}-c{{ thirdCate.id }}">{{ thirdCate.name }}</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><a [routerLink]="['/',currentLanguage,'giai-phap']">{{"giaiphap" | translate}}</a>
                        <ng-container *ngIf="giaiphaps"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                        <ul>
                            <li *ngFor="let item of giaiphaps"><a [routerLink]="['/',currentLanguage, item.slug]">{{ item.name }}</a></li>
                        </ul>
                    </li>
                    <li><a [routerLink]="['/',currentLanguage,'dich-vu']">{{"dichvu" | translate}}</a>
                        <ng-container *ngIf="services"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                        <ul>
                            <li *ngFor="let item of services"><a [routerLink]="['/',currentLanguage, item.slug]">{{ item.name }}</a></li>
                        </ul>
                    </li>
                    <li><a [routerLink]="['/',currentLanguage,'tin-tuc']">{{"tintuc" | translate}}</a>
                        <ng-container *ngIf="tintucs"><button class="dropdown-toggle-btn btn"><i class="fas fa-angle-right"></i></button></ng-container>
                        <ul>
                            <li *ngFor="let item of tintucs"><a [routerLink]="['/',currentLanguage, item.slug]">{{ item.name }}</a></li>
                        </ul>
                    </li>
                    <li><a routerLink="/{{currentLanguage}}/thuong-hieu">{{"hangsanxuat" | translate}}</a></li>
                    <li><a routerLink="/{{currentLanguage}}/tuyen-dung">{{"tuyendung" | translate}}</a></li>
                    <li><a routerLink="/{{currentLanguage}}/lien-he">{{"lienhe" | translate}}</a></li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div *ngIf="!dataLoaded" class="loading-spinner mt-3">
    <div class="skeleton-box" style="height: 40px;"></div>
</div>