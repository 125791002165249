import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { NewsService } from '../service/news.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../home/service/home.service';

@Component({
  selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  styleUrl: './list-news.component.css'
})
export class ListNewsComponent {
  title:string='';
  keytitle:string='';
  type:string='';
  com:string='';
  news:any=[];
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  
  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private homeService: HomeService
  ){}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.title = data['title'];
      this.com = data['com'];
      //this.updateTitle(this.keytitle);
      
      this.homeService.seoSetting(this.type).subscribe((resp:any)=>{
        if(resp.code==200){
          this.settingsService.updateMetaTags(resp.seo.title, resp.seo.description, resp.seo?.image?.thumbnail, "");
          this.settingsService.updateSchema(resp.seo.code_schema);
        }else{
          this.settingsService.settings$.subscribe((settings:any) => {
            if(settings) this.settingsService.updateMetaTags(this.title, settings?.description, settings?.logo, settings?.title);
          });
        }
      })
    });
    // this.languageService.language$.subscribe(() => {
    //   this.updateTitle(this.keytitle);
    // });
    this.activedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.listNews(this.currentPage); // Tải dữ liệu cho trang hiện tại
    });
  }
  private updateTitle(data:any): void {
    this.translate.get(data).subscribe((text: string) => {
      this.title = text;
    });
  }
  listNews(page=1){
    this.newsService.getNews(page,this.type).subscribe((resp:any)=>{
      this.news=resp.news;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.listNews(page);
  }
}
