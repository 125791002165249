import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations';
import { SettingsService } from 'src/app/services/settings.service';
import { HomeService } from '../../home/service/home.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { Base } from 'src/app/shared/base';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations:[
    trigger("animateFooter", [
      transition(":enter", [
        query("*", [
          style({opacity: 0, transform: "translateY(100%)"}),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity:1, transform: "none"})
            )
          ])
        ])
      ])
    ])
  ]
})
export class FooterComponent implements OnInit {
  footer:any=[];
  support:any=[];
  settings:any=null;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  constructor(
    private homeService: HomeService,
    private settingsService: SettingsService,
    public languageService: LanguageService,
  ){}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.homeService.fetchFooter().subscribe((data:any)=>{
      if (data) {
        this.footer = data.footer;
        this.support = data.support;
      }
    });
    // this.homeService.footer$.subscribe((data) => {
    //   if (data) {
    //     this.footer = data.footer;
    //     this.support = data.support;
    //   }
    // });
  
    // Gọi fetchFooter nếu dữ liệu chưa có
    // if (!this.footer || !this.footer.length) {
    //   this.homeService['fetchFooter']();
    // }
    
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }
}
