import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBrandComponent } from './brand-categorie/filter-brand/filter-brand.component';
import { ListProductComponent } from './list-product/list-product.component';
import { FilterComponent } from './filter/filter.component';
import { DetailComponent } from './detail/detail.component';
import { CommentComponent } from './detail/comment/comment.component';
import { SliderComponent } from './detail/slider/slider.component';
import { CategoriesComponent } from './categories/categories.component';
import { BrandCategorieComponent } from './brand-categorie/brand-categorie.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLazyloadModule } from '../general/images-lazyload/images-lazyload.module';
import { ProductComponent } from './product.component';
import { ArticleModule } from '../article/article.module';
import { FormsModule } from '@angular/forms';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    FilterBrandComponent,
    ListProductComponent,
    FilterComponent,
    DetailComponent,
    CommentComponent,
    SliderComponent,
    CategoriesComponent,
    BrandCategorieComponent,
    ProductComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    NgbPaginationModule,
    ImagesLazyloadModule,
    ArticleModule,
    FormsModule,
    YouTubePlayerModule,
    TranslateModule
  ]
})
export class ProductModule { }
