import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { ProductService } from '../product/service/product.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  settings:any=[];
  title:string="Kết quả tìm kiếm";
  keyword: string = '';
  keywordDecode: string = '';
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;

  sliderKey!: string;

  
  constructor(
    private productService: ProductService,
    public activedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private router: Router,
    public languageService: LanguageService,
    private metaService: Meta,
  ){}

  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.keyword = resp.keyword;console.log(this.keyword);
      this.keywordDecode = decodeURIComponent(resp.keyword);
      this.settingsService.settings$.subscribe((settings:any) => {
        this.settingsService.updateMetaTags("Kết quả tìm kiếm - "+decodeURIComponent(resp.keyword), settings?.description, settings?.logo, settings?.title);
        this.metaService.updateTag({ name: 'robots', content: "noodp,noindex,noindex" });
      });
      this.currentPage = resp['page'] ? +resp['page'] : 1;
      this.getProducts(this.currentPage);
    });
    this.getProducts();
  }

  getProducts(page=1): void {
    this.productService.getProductsSearch(page, this.keyword).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(this.currentPage);
  }
}
