<main class="bg-gray-100 py-3">
    <div class="container bg-white p-3">
        <div class="content1x postDetail">
            <section class="breadcrumb__area include-bg mb-3">
                <div class="breadcrumb__content position-relative z-index-1">
                   <div class="breadcrumb__list mb-3">
                      <span><a routerLink="/{{currentLanguage}}/">{{"trangchu" | translate}}</a></span>
                      <span><a routerLink="/{{currentLanguage}}/{{com}}">{{title}}</a></span>
                      <span>{{ blog.name }}</span>
                   </div>
                </div>
            </section>
            <h1 class="title">{{ blog.name }}</h1>
            <div class="date">
                <i class="fas fa-calendar-alt"></i> {{ blog.created_at }}
            </div>
            <div class="my-3" [innerHTML]="blog.content"></div>
        </div>
        
    </div>
</main>