import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ArticleComponent } from './article.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftProductComponent } from '../general/left-product/left-product.component';
import { ListArticleComponent } from './list-article/list-article.component';
import { ImagesLazyloadModule } from '../general/images-lazyload/images-lazyload.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../home/home.module';
import { DetailArticleComponent } from './detail-article/detail-article.component';



@NgModule({
  declarations: [
    ArticleComponent,
    ListArticleComponent,
    LeftProductComponent,
    DetailArticleComponent
  ],
  exports: [
    LeftProductComponent,  // Export component này để có thể sử dụng ở module khác
    // Các component khác cần export
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    RouterOutlet,
    NgbPaginationModule,
    ImagesLazyloadModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ]
})
export class ArticleModule { }
