import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { URL_SERVICEIO } from 'src/app/config/config';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private apiUrl = URL_SERVICEIO+'/frontEnd/products'; // Thay thế với URL thực tế của API
  private maxPriceUrl = URL_SERVICEIO+'/frontEnd/products/max-price'; // Endpoint mới cho giá cao nhất

  constructor(
    private http: HttpClient,private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private settingsService: SettingsService,
    private languageService: LanguageService
  ) {
    let currentLanguage = this.languageService.currentLanguage; 
  }

  private appendLangToParams(params: HttpParams, lang: string): HttpParams {
    return params.set('lang', lang);
  }

  getProducts(page: number): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams().set('page', page), lang);
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }
  filterProducts(minPrice: number, maxPrice: number, page: number): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams().set('minPrice', minPrice).set('maxPrice', maxPrice).set('page', page),
          lang
        );
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }
  getPriceSearchs(categoryId: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/prices?categoryId=${categoryId}&lang=${lang}`;
        return this.http.get(URL);
      })
    );
  }

  getPriceSearchs_Brand(): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/prices?lang=${lang}`;
        return this.http.get(URL);
      })
    );
  }
  getOptionSearch(id: string, brand: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/optionSearch`;
        const params = this.appendLangToParams(
          new HttpParams().set('categoryId', id).set('brand_id', brand),
          lang
        );
        return this.http.get<any>(URL, { params });
      })
    );
  }
  getProductsSearch(page: number, keyword: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams().set('keyword', keyword).set('page', page),
          lang
        );
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }

  filtterProductsCategorie(minPrice: number, maxPrice: number, page: number, id:string, type_categorie:number): Observable<any> {
      return this.languageService.language$.pipe(
        switchMap((lang) => {
          let params = new HttpParams()
            .set('minPrice', minPrice)
            .set('maxPrice', maxPrice)
            .set('page', page);
  
          if (type_categorie === 1) {
            params = params.set('first_category_id', id);
          } else if (type_categorie === 2) {
            params = params.set('second_category_id', id);
          } else {
            params = params.set('third_category_id', id);
          }
  
          params = this.appendLangToParams(params, lang);
          return this.http.get<any>(this.apiUrl, { params });
        })
      );
  }
  getMaxPrice(): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams(), lang);
        return this.http.get<any>(this.maxPriceUrl, { params });
      })
    );
  }
  getMaxPriceCategorie(id: string, typeCategorie: number): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        let params = new HttpParams();

        if (typeCategorie === 1) {
          params = params.set('first_category_id', id);
        } else if (typeCategorie === 2) {
          params = params.set('second_category_id', id);
        } else {
          params = params.set('third_category_id', id);
        }

        params = this.appendLangToParams(params, lang);
        return this.http.get<any>(this.maxPriceUrl, { params });
      })
    );
  }
  getCategories(): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/leftCategories?lang=${lang}`;
        return this.http.get(URL);
      })
    );
  }
  getCategoriesDetail(slug: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/categorie_detail/${slug}?lang=${lang}`;
        return this.http.get(URL).pipe(
          tap((resp: any) => {
            this.settingsService.settings$.subscribe((settings: any) => {
              const titleCustom = resp.categorie?.title || resp.categorie?.name;
              const descriptionCustom = resp.categorie?.description || settings?.description;
              const imageUrl = resp.categorie?.image?.photo || settings?.logo;
              this.settingsService.updateMetaTags(titleCustom, descriptionCustom, imageUrl, settings.title);
            });
          })
        );
      })
    );
  }
  getProductsCategorie(
    page: number,
    id: string,
    price: string,
    brand: string,
    order: string,
    option: string,
    origin: string
  ): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams()
            .set('categoryId', id)
            .set('price', price)
            .set('brand_id', brand)
            .set('option_id', option)
            .set('origin_id', origin)
            .set('sort_by', order)
            .set('page', page),
          lang
        );
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }
  getCategoryChild(
    page: number,
    id: string
  ): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams()
            .set('categoryId', id)
            .set('page', page),
          lang
        );
        let URL=URL_SERVICEIO+'/frontEnd/category_childs';
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }
  getCategoriesBrand(slug: string, brand: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/categorie_brand/${slug}/${brand}?lang=${lang}`;
        return this.http.get(URL).pipe(
          tap((resp: any) => {
            this.settingsService.settings$.subscribe((settings: any) => {
              const titleCustom = `${resp.categorie?.name} - ${resp.brand?.name}`;
              const descriptionCustom =
                resp.categorie?.description || settings?.description;
              const imageUrl =
                resp.categorie?.image?.photo || settings?.logo;
  
              // Cập nhật thẻ meta
              this.settingsService.updateMetaTags(
                titleCustom,
                descriptionCustom,
                imageUrl,
                settings.title
              );
            });
          })
        );
      })
    );
  }
  
  

  // showProduct(slug:string, id:string){
  //   let URL= URL_SERVICEIO+"/frontEnd/products/"+slug+"/"+id;
  //   //return this.http.get(URL);
  //   const PRODUCT_KEY = makeStateKey<any>('product-' + id);

  //   if (this.state.hasKey(PRODUCT_KEY)) {
  //     // Lấy dữ liệu từ TransferState, không gọi lại API
  //     const product = this.state.get(PRODUCT_KEY, null);
  //     return of(product);
  //   } else {
  //     return this.http.get(URL).pipe(
  //       tap((product: any) => {
  //         if (isPlatformBrowser(this.platformId)) {
  //           this.state.set(PRODUCT_KEY, product);
  //         }
  //         this.settingsService.settings$.subscribe((settings:any) => {
  //           const title_custom = product.product?.title ? product.product?.title : product.product?.name;
  //           // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
  //           const description_custom = product.product?.description ? product.product?.description : settings?.description;
  //           // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
  //           const imageUrl = product.product?.image ? product.product?.image?.photo : settings?.logo;
  //           this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
  //         });
  //       })
  //     );
  //   }
  // }
  showProduct(slug: string, id: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/products/${slug}/${id}?lang=${lang}`;
        return this.http.get(URL).pipe(
          tap((product: any) => {
            this.settingsService.settings$.subscribe((settings: any) => {
              const titleCustom = product.product?.title || product.product?.name;
              const descriptionCustom = product.product?.description || settings?.description;
              const imageUrl = product.product?.image?.photo || settings?.logo;
              this.settingsService.updateMetaTags(titleCustom, descriptionCustom, imageUrl, settings.title);
              this.settingsService.updateSchema(product.schema);
            });
          })
        );
      })
    );
  }
  addComment(data:any){
    let URL= URL_SERVICEIO+"/frontEnd/comments";
    return this.http.post(URL,data).pipe()
  }
  getcomments(product_id:number,page:number){
    let URL=URL_SERVICEIO+"/frontEnd/comments/product/"+product_id+"?page="+page;
    return this.http.get(URL);
  }
}
