import { Component } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { BrandService } from '../service/brand.service';
import { Base } from 'src/app/shared/base';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../home/service/home.service';

@Component({
  selector: 'app-list-brand',
  templateUrl: './list-brand.component.html',
  styleUrl: './list-brand.component.css'
})
export class ListBrandComponent {
  title: string = '';
  settings:any=[];

  brands:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  constructor(
    private brandService: BrandService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private homeService: HomeService
  ){}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
    this.updateTitle();
    this.languageService.language$.subscribe(() => {
      this.updateTitle();
    });
    this.homeService.seoSetting('thuong-hieu').subscribe((resp:any)=>{
      if(resp.code==200){
        this.settingsService.updateMetaTags(resp.seo.title, resp.seo.description, resp.seo?.image?.thumbnail, "");
        this.settingsService.updateSchema(resp.seo.code_schema);
      }else{
        this.settingsService.settings$.subscribe((settings:any) => {
          if(settings){
            this.settingsService.updateMetaTags(this.title, settings?.description, settings?.logo, settings?.title);
          }
        });
      }
    })
    this.listBrands();
  }
  private updateTitle(): void {
    this.translate.get('hangsanxuat').subscribe((res: string) => {
      this.title = res; // Cập nhật tiêu đề
    });
  }
  listBrands(page=1){
    this.brandService.getAllBrand(page).subscribe((resp:any) =>{
      this.brands=resp.brands;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage($event:any){
    this.listBrands($event);
  }
}
