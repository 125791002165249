import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private supportedLanguages: ('vi' | 'en' | 'cn')[] = ['vi', 'en', 'cn'];
  private languageSubject!: BehaviorSubject<'vi' | 'en' | 'cn'>; // Sử dụng dấu `!` để đảm bảo được khởi tạo
  language$ = new BehaviorSubject<'vi' | 'en' | 'cn'>('vi').asObservable(); // Khởi tạo tạm thời

  constructor(
    private router: Router,
    private location: Location,
    public translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Khởi tạo languageSubject và language$
    const initialLang = this.getInitialLang();
    this.languageSubject = new BehaviorSubject<'vi' | 'en' | 'cn'>(initialLang);
    this.language$ = this.languageSubject.asObservable();
  }

  // initLanguage(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const urlLang = this.getLangFromUrl(window.location.href);
  //     const lang = this.supportedLanguages.includes(urlLang) ? urlLang : 'vi';

  //     this.languageSubject.next(lang);
  //     this.translateService.addLangs(this.supportedLanguages);
  //     this.translateService.setDefaultLang(lang);
  //     this.document.documentElement.lang = lang;
  //   }
  // }
  initLanguage() {
    if (isPlatformBrowser(this.platformId)) {
      const urlPath = window.location.pathname.split('/');
      let language = urlPath[1] as 'vi' | 'en' | 'cn';

      if (!this.supportedLanguages.includes(language)) {
        language = 'vi';
        this.router.navigate([`/${language}`], { replaceUrl: true });
      }

      this.translateService.addLangs(this.supportedLanguages);
      this.translateService.setDefaultLang(language);
      this.languageSubject.next(language);
    }
  }

  changeLanguage(language: 'vi' | 'en' | 'cn'): void {
    if (this.supportedLanguages.includes(language)) {
      const currentPath = this.location.path(); // Lấy đường dẫn hiện tại
      const currentLang = this.languageSubject.value; // Ngôn ngữ hiện tại
  
      // Thay thế ngôn ngữ hiện tại bằng ngôn ngữ mới trong URL
      const newPath = currentPath.replace(`/${currentLang}`, `/${language}`);
      this.languageSubject.next(language);
      this.translateService.setDefaultLang(language);
  
      // Load lại toàn bộ trang với URL mới
      window.location.href = `${window.location.origin}${newPath}`;
    }
  }

  private getInitialLang(): 'vi' | 'en' | 'cn' {
    let initialLang: 'vi' | 'en' | 'cn' = 'vi'; // Ngôn ngữ mặc định

    // Lấy ngôn ngữ từ URL trên server
    if (isPlatformServer(this.platformId)) {
      const url = this.document.location.href || '';
      const urlLang = this.getLangFromUrl(url);
      if (this.supportedLanguages.includes(urlLang)) {
        initialLang = urlLang;
      }
    }

    return initialLang;
  }

  private getLangFromUrl(url: string): 'vi' | 'en' | 'cn' {
    const segments = url.split('/');
    return segments[3] as 'vi' | 'en' | 'cn'; // Dựa vào cấu trúc URL
  }

  get currentLanguage(): 'vi' | 'en' | 'cn' {
    return this.languageSubject.value;
  }
}
