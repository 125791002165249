<div class="container mb-3">
<div class="slider">
    <owl-carousel-o class='owl-carousel-o' [options]="customOptions">
        <ng-container *ngFor="let slider of sliders">
            <ng-template carouselSlide [id]="slider.title+slide+i">
                <div #imgContainer class='img-container'>
                    <img *ngIf="slider.photo; else usePhoto" appLazyLoad="{{ slider?.photo }}" src="assets/images/1280x410.webp" alt="{{slider?.title}}" class="img-feature-proyect img-fluid" loading="lazy" onerror="this.onerror=null;this.src='assets/images/1280x410.jpg';" width="1280px" height="410px" >
                    <!-- <img class='img-feature-proyect rounded' [src]="slider.photo" [alt]="slider.title"> -->
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
</div>