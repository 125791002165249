import { Component } from '@angular/core';
import { ProductService } from '../service/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language/language.service';
import { Base } from 'src/app/shared/base';
import { SettingsService } from 'src/app/services/settings.service';
import { HomeService } from '../../home/service/home.service';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrl: './list-product.component.css'
})
export class ListProductComponent extends Base {
  settings: any = [];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  brands: any[] = [];
  origins: any[] = [];
  guarantees: any[] = [];
  optionsSearch: any[] = [];
  filteredBrands:any=[];
  priceFilter:string='';
  isShowOrderby: boolean = false;
  brandId:string ='';
  priceId:string='';
  optionId:string='';
  originId:string='';
  guaranteeId:string='';
  totalSearch:number = 0;
  categorie_slug:string='';
  categorie:any=[];
  categorie_id:string='';

  isContentExpanded: boolean = false;
  currentSort: string = 'new'; // Giá trị sắp xếp mặc định
  sortedProducts: any[] = [];
  categories:any=[];

  optionSelected:any=[];
  brandSelected:any=[];
  priceSelected:any=[];
  dataLoaded:boolean=false;

  
  constructor(
    private productService: ProductService,
    private router: Router,
    languageService: LanguageService,
    public activedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private homeService: HomeService
  ){
    super(languageService);
  }
  ngOnInit(): void {
    this.homeService.seoSetting('product').subscribe((resp:any)=>{
      if(resp.code==200){
        this.settingsService.updateMetaTags(resp.seo.title, resp.seo.description, resp.seo?.image?.thumbnail, "");
        this.settingsService.updateSchema(resp.seo.code_schema);
      }else{
        this.settingsService.settings$.subscribe((settings:any) => {
          if(settings) this.settingsService.updateMetaTags("Sản phẩm", settings?.description, settings?.logo, settings?.title);
        });
      }
    })
    this.activedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.getProducts(this.currentPage); // Tải dữ liệu cho trang hiện tại
    });
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, "", "","", this.currentSort, "", "").subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(page);
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
  loadFilters() {
    this.productService.getPriceSearchs(this.categorie_id).subscribe((resp: any) => {
      this.prices = resp.prices;
      this.brands = resp.brands;
      this.origins = resp.origins;
      this.guarantees = resp.guarantees;
      this.dataLoaded = true;
    });
  }

  onFilterChanged(filters: any) {
    this.applyFilters(filters);
  }

  applyFilters(filters: any) {
    this.brandId = filters.brands.map((brand: any) => brand.id).join(',');
    this.priceFilter = filters.prices.map((price: any) => price.id).join(',');
    this.optionId = filters.options.map((option: any) => option.id).join(',');
    this.originId = filters.origins.map((origin: any) => origin.id).join(',');
    // const guaranteeIds = filters.guarantees.map((guarantee: any) => guarantee.id).join(',');

    this.router.navigate([], {
      queryParams: {
        brand: this.brandId,
        p: this.priceFilter,
        o: this.optionId,
        sx: this.originId,
        // g: guaranteeIds
      },
      queryParamsHandling: 'merge'
    });
    this.getProducts();
  }

  clearAllFilters() {
    this.router.navigate([], { queryParams: {} });
    this.totalSearch = 0;
    this.getProducts();
  }
  
  getActiveSortText() {
    switch (this.currentSort) {
      case 'popularity': return 'Bán chạy nhất';
      case 'new': return 'Mới nhất';
      case 'price': return 'Giá thấp đến cao';
      case 'price-desc': return 'Giá cao đến thấp';
      default: return '';
    }
  }
  sortProducts(orderBy: string) {
    this.currentSort=orderBy;
    this.getProducts();
    this.isShowOrderby=false;
  }
  showOrderby(){
    this.isShowOrderby = !this.isShowOrderby;
  }
}
