<ng-container >
    <!-- <div id="sync1" class="owl-carousel owl-theme">
       <div class="item">
          <a [href]="product.image.thumbnail2x" class="ioverlay" rel="gallery" title="{{ product.name }}" data-fancybox="images" >
             <img [src]="product.image.thumbnail2x" alt="{{ product.name }}"  class="img-fluid lazyload" />
          </a>
       </div>
       <div class="item" *ngFor="let image of product.images;">
          <a [href]="image.photo" class="ioverlay" rel="gallery" title="{{ product.name }}" data-fancybox="images" >
             <img [src]="image.image" alt="{{ product.name }}"  class="img-fluid lazyload" />
          </a>
       </div>
    </div>
   
    <div id="sync2" class="owl-carousel owl-theme">
       <div class="item" [class.active]="selectedImageIndex === 0">
          <img [src]="product.image.thumbnail" alt="{{ product.name }}"  class="img-fluid lazyload" />
       </div>
       <div class="item" *ngFor="let image of product.images; let i = index" [class.active]="selectedImageIndex === (i + 1)">
          <img [src]="image.thumbnail" alt="{{ product.name }}"  class="img-fluid lazyload" />
       </div>
    </div> -->
    <ul #imageGallery id="image-gallery" class="gallery list-unstyled cS-hidden"  *ngIf="product.image; else detailPhoto">
       <li
           [attr.data-thumb]="product.image.thumbnail2x"
           class="aoverlay"
           [style.background-image]="'url(' + product.image.photo + ')'"
       >
           <a
               [href]="product.image.photo"
               class="ioverlay"
               rel="gallery"
               title="{{ product.name }}"
               data-fancybox="images"
           >
               <img
                   [src]="product.image.thumbnail2x"
                   alt="{{ product.name }}"
                   class="img-fluid lazyload"
               />
           </a>
       </li>
       <ng-container *ngIf="product.images?.length > 0" >
          <ng-container *ngFor="let imagen of product.images; trackBy: trackById">
           <li [attr.data-thumb]="imagen.image" class="aoverlay" [style.background-image]="'url(' + imagen.photo + ')'"  *ngIf="imagen.image!=null" >
               <a
                   [href]="imagen.photo"
                   class="ioverlay"
                   rel="gallery"
                   title="{{ product.name }}"
                   data-fancybox="images"
               >
                   <img
                       [src]="imagen.image"
                       alt="{{ product.name }}"
                       class="img-fluid lazyload"
                   />
               </a>
           </li>
          </ng-container>
       </ng-container>
   </ul>
   </ng-container>
   <ng-template #detailPhoto>
      <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
   </ng-template>