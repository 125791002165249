import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { URL_WEBSITE } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrl: './order.component.css'
})
export class OrderComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  orderCode:string='';
  settings:any=[];
  order:any=[];
  orderDetail:any=[];
  orderInfoVisible: boolean = false;
  title:string= "Tra cứu đơn hàng";
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private settingsService: SettingsService,
    public languageService: LanguageService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cartService: CartService,
    private titleService: Title,
    private metaService: Meta
  ){

  }
  ngOnInit(): void {
    this.updateTitle();
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
      this.updateTitle();
    });
    this.settingsService.settings$.subscribe(
      (data) => {
        if(data){
          this.settings = data;
          this.updateMetaTags();
        }
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }
  private updateTitle(): void {
    this.translate.get('tracuu').subscribe((res: string) => {
      this.title = res; // Cập nhật tiêu đề
    });
  }
  getPaymentText(): string {
    return this.order.payment === 'cod'
      ? this.translate.instant('thanhtoankhinhanhang')
      : this.translate.instant('thanhtoanchuyenkhoan');
  }
  onSubmit(){
    if (!this.orderCode) {
      this.translate.get('vuilongnhapmadonhang').subscribe((message: string) => {
        this.toastr.error(message);
      });
      return;
    }
    this.cartService.getOrderCode(this.orderCode).subscribe((resp:any) =>{
      if(resp.code==200){
        this.order=resp.order;
        this.orderDetail=resp.order_details;
        this.orderInfoVisible = true;
      }else{
        this.orderInfoVisible = false;
        this.toastr.error(resp.message);
      }
    },(error) => {
      this.orderInfoVisible = false;
      //console.error('Error fetching settings:', error);
      this.toastr.error(error.error.message);
    })
  }
  getStatusText(status: number): string {
    let statusKey = status.toString();
    let statusText = '';

    this.translate.get(`trangthaidh.${statusKey}`).subscribe((text: string) => {
      statusText = text;
    });

    return statusText || this.translate.instant('trangthaidh.default');
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
