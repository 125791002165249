<div id="box_comment" class="p-3 bg-white mt-3">
    <div class="title">{{"danhgia" | translate}}</div>
    <div class="comment-rating mb-3">
       <label for="rating" class="mb-2">{{"danhgiacuaban" | translate}}: (*)</label>
       <p class="stars">
          <span class="star-1 cursor-pointer" (click)="starsRate(1)" [ngClass]="{'active': comment.rate==1}"><i class="fas fa-star"></i></span>
          <span class="star-2 cursor-pointer" href="javascript:void(0)" (click)="starsRate(2)" [ngClass]="{'active': comment.rate==2}"><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
          <span class="star-3 cursor-pointer" href="javascript:void(0)" (click)="starsRate(3)" [ngClass]="{'active': comment.rate==3}"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
          <span class="star-4 cursor-pointer" href="javascript:void(0)" (click)="starsRate(4)" [ngClass]="{'active': comment.rate==4}"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
          <span class="star-5 cursor-pointer" href="javascript:void(0)" (click)="starsRate(5)" [ngClass]="{'active': comment.rate==5}"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
       </p>
    </div>
    <div class="comment-rating mb-3">
       <label class="mb-2" for="">{{"nhanxetcuaban" | translate}} (*)</label>
       <textarea name="comment" id="content-comment" class="form-control" [(ngModel)]="comment.content" row="4"></textarea>
    </div>
    <div class="comment-rating mb-3 row">
       <div class="col-md-6 col-12 mb-3">
          <label class="mb-2" for="">{{"tencuaban" | translate}} (*)</label>
          <input type="text" class="form-control" name="nameComment" [(ngModel)]="comment.name" />
       </div>
       <div class="col-md-6 col-12 mb-3">
          <label class="mb-2" for="">{{"emailcuaban" | translate}} (*)</label>
          <input type="text" class="form-control" name="EmailComment" [(ngModel)]="comment.email" />
       </div>
    </div>
    <div class="mb-3">
       <button class="btn btn-primary" (click)="save()">{{"guidanhgia" | translate}}</button>
    </div>
    <div class="box_comment mt-5" *ngIf="comments.length>0">
        <div class="comment-item mb-5" *ngFor="let comment of comments">
            <div class="d-flex align-items-center">
                <img src="assets/images/icon_user.png" alt="{{ comment.name }}" class="img-fluid" />
                <div class="info">
                    <div class="name">{{ comment.name }}</div>
                    <div class="date d-flex flex-wrap align-items-center">
                        {{ comment.created }}
                        <div class="stars ms-2">
                            <i *ngFor="let star of stars; let i = index" class="fa fa-star" [ngClass]="{'orange': i < comment.rate}"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content mt-3">{{ comment.content }}</div>
        </div>
        <ngb-pagination *ngIf="totalPages/15>1"
         [collectionSize]="totalPages"
         [(page)]="currentPage"
         [pageSize]="15"
         [rotate]="true"
         [boundaryLinks]="true"
         (pageChange)="loadPage($event)"
         [maxSize]="5"
   ></ngb-pagination>
    </div>
 </div>