import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SitemapService } from 'src/app/services/sitemap.service';

@Component({
  selector: 'app-sitemap',
  standalone: true,
  imports: [],
  templateUrl: './sitemap.component.html',
  styleUrl: './sitemap.component.css'
})
export class SitemapComponent {
  sitemap: SafeHtml = '';
  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private sitemapService: SitemapService) {}
  ngOnInit(): void {
    //const data = this.route.snapshot.data;
    this.generateSitemap();
  }

  generateSitemap(): void {
    // Gọi API để lấy dữ liệu (cần một API backend trả dữ liệu danh sách URL)
    this.sitemapService.getSitemapData().subscribe((data:any)=>{
      const sitemapXml = this.createSitemapXml(data);
      this.sitemap = this.sanitizer.bypassSecurityTrustHtml(sitemapXml);

      // Hiển thị XML trực tiếp
      document.write(sitemapXml);
    })
  }

  createSitemapXml(data: any): string {
    const lastmod = new Date().toISOString();
    let sitemap = `<?xml version="1.0" encoding="UTF-8"?>\n<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

    data.sitemaps.forEach((item: any) => {
      sitemap += `  <sitemap>\n`;
      sitemap += `    <loc>${item.loc}</loc>\n`;
      sitemap += `    <lastmod>${lastmod}</lastmod>\n`;
      sitemap += `  </sitemap>\n`;
    });

    sitemap += `</sitemapindex>`;
    return sitemap;
  }
}
