import { Component } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router, RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-history-order',
  standalone: true,
  imports: [NgbPaginationModule,CommonModule, RouterModule, TranslateModule],
  templateUrl: './history-order.component.html',
  styleUrl: './history-order.component.css'
})
export class HistoryOrderComponent {
  user: any;
  token: string | null = null;
  orders:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  constructor(
    private authService: AuthService,
    public router: Router,
    public languageService: LanguageService,
    private translate: TranslateService,
  ) {}
  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.authService.token$.subscribe((token) => {
      this.token = token;
    });
    
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });

    if(!this.token && !this.user){
      setTimeout(() => {
        this.router.navigateByUrl("/login");
      }, 500);
      return;
    }
    this.listOrders();
  }
  listOrders(page=1){
    this.authService.getOrder(page,this.user.id).subscribe((resp:any)=>{
      this.orders=resp.orders;
      this.totalPages=resp.total;
      this.currentPage=page
    })
  }
  loadPage($event:any){
    this.listOrders($event);
  }
  getStatusText(status: number): string {
    let statusKey = status.toString();
    let statusText = '';

    this.translate.get(`trangthaidh.${statusKey}`).subscribe((text: string) => {
      statusText = text;
    });

    return statusText || this.translate.instant('trangthaidh.default');
  }
  // getStatusText(status: number): string {
  //   switch (status) {
  //     case 1:
  //       return 'Mới đặt';
  //     case 2:
  //       return 'Đang xử lý';
  //     case 3:
  //       return 'Đang vận chuyển';
  //     case 4:
  //       return 'Đã giao hàng';
  //     default:
  //       return 'Không xác định';
  //   }
  // }
  getStatusclass(status: number): string {
    switch (status) {
      case 1:
        return 'text-primary';
      case 2:
        return 'text-warning';
      case 3:
        return 'text-info';
      case 4:
        return 'text-success';
      default:
        return 'text-secondary';
    }
  }
}
