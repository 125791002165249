<main class="bg-gray-100 py-5">

    <!-- profile area start -->
    <section class="profile__area pt-120 pb-120">
     <div class="container">
           <div class="row">
              <div class="col-xxl-4 col-lg-4">
                 <div class="profile__tab me-5 bg-white">
                    <a class="nav-link " routerLink="/{{currentLanguage}}/profile"><span><i class="fas fa-user"></i></span>{{"thongtintaikhoan" | translate}}</a>
                    <a class="nav-link " routerLink="/{{currentLanguage}}/lich-su-mua-hang"><span><i class="fas fa-clipboard-list-check"></i></span>{{"lichsumuahang" | translate}}</a>
                    <a class="nav-link active" routerLink="/{{currentLanguage}}/change-pass"><span><i class="fas fa-lock"></i></span>{{"doimatkhau" | translate}}</a>
                    <a class="nav-link" routerLink="/{{currentLanguage}}/logout"><span><i class="fas fa-times"></i></span>{{"thoat" | translate}}</a>
                    <span id="marker-vertical" class="tp-tab-line d-none d-sm-inline-block"></span>
                 </div>
              </div>
              <div class="col-xxl-8 col-lg-8">
                <div class="profile_form bg-white p-md-5 p-3">
                    <div class="mb-4 row">
                        <div class="col-md-4 col-12 text-md-end text-start">Email</div>
                        <div class="col-md-8 col-12">
                            <input type="text" class="form-control" [(ngModel)]="email" readonly />
                        </div>
                    </div>
                    <div class="mb-4 row">
                        <div class="col-md-4 col-12 text-md-end text-start">{{"hoten"|translate}}</div>
                        <div class="col-md-8 col-12">
                            <input type="text" class="form-control" [(ngModel)]="name" placeholder=""/>
                        </div>
                    </div>
                    <div class="mb-4 row">
                        <div class="col-md-4 col-12 text-md-end text-start">{{"tenrutgon"|translate}}</div>
                        <div class="col-md-8 col-12">
                            <input type="text" class="form-control" [(ngModel)]="surName" />
                        </div>
                    </div>
                    <div class="mb-4 row">
                        <div class="col-md-4 col-12 text-md-end text-start">{{"diachi"|translate}}</div>
                        <div class="col-md-8 col-12">
                            <input type="text" class="form-control" [(ngModel)]="address" />
                        </div>
                    </div>
                    <div class="mb-4 row">
                        <div class="col-md-4 col-12 text-md-end text-start">{{"dienthoai"|translate}}</div>
                        <div class="col-md-8 col-12">
                            <input type="text" class="form-control" [(ngModel)]="phone" />
                        </div>
                    </div>
                    <div class="text-center">
                        <div *ngIf="isLoading" class="loading-indicator">
                            {{"dangxuly"|translate}}
                         </div>
                        <input type="submit" value="{{'capnhat'|translate}}" class="btn btn-primary" [disabled]="isLoading" (click)="saveUser()">
                    </div>
                </div>
              </div>
           </div>
        </div>
    </section>
 <!-- profile area end -->

  </main>