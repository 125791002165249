import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { URL_SERVICEIO } from 'src/app/config/config';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(
    private http: HttpClient, private settingsService: SettingsService,
    private languageService: LanguageService
  ) { }
  getNews(page: number, type: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const URL = `${URL_SERVICEIO}/frontEnd/news?page=${page}&type=${type}&lang=${lang}`;
        return this.http.get(URL);
      })
    );
  }
  getNews1(page:number,type:string){
    let URL= URL_SERVICEIO+"/frontEnd/news?page="+page+"&type="+type;
    return this.http.get(URL);
  }
  getNewsCategories(page:number,id:string){
    let URL= URL_SERVICEIO+"/frontEnd/news/"+id+"?page="+page;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp?.categorie?.title ? resp.categorie?.title : resp?.categorie?.name;
          const description_custom = resp?.categorie?.description ? resp?.categorie?.description : settings?.description;
          const imageUrl = resp?.categorie?.image ? resp.categorie?.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }
  getNewsDetail(categorySlug:string, id:string){
    let URL=URL_SERVICEIO+"/frontEnd/news/"+categorySlug+"/"+id;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.newsDetail?.title ? resp.newsDetail?.title : resp.newsDetail?.name;
          const description_custom = resp.newsDetail?.description ? resp.newsDetail?.description : settings?.description;
          const imageUrl = resp.newsDetail?.image ? resp.newsDetail?.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
          this.settingsService.updateSchema(resp.schema);
        });
      })
    );
  }
}
