import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { URL_SERVICEIO, URL_WEBSITE } from '../config/config';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from './language/language.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public settings$: Observable<any> = this.settingsSubject.asObservable();
  private navDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private languageSubscription: Subscription;

  private apiUrl = URL_SERVICEIO+"/frontEnd/setting"; // Thay bằng URL API của bạn

  constructor(private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    let currentLanguage = this.languageService.currentLanguage; // Truy cập getter như một thuộc tính

    // this.languageSubscription = this.languageService.language$.pipe(distinctUntilChanged()).subscribe((newLang) => {
    //   if (newLang !== currentLanguage) {
    //     this.loadSettings();
    //     currentLanguage = newLang;
    //   }
    // });
  }

  loadSettings(): void {
    const lang = this.languageService.currentLanguage;
    if (this.settingsSubject.getValue()?.lang === lang) {
      return;
    }
    const URL = `${URL_SERVICEIO}/frontEnd/setting?lang=${lang}`;
    this.http.get(URL).subscribe(
      (resp: any) => {
        this.settingsSubject.next(resp.setting);
        if (isPlatformBrowser(this.platformId) && resp.setting?.code_schema) {
          const scriptTag = document.createElement('script');
          scriptTag.type = 'application/ld+json';
          scriptTag.text = resp.setting?.code_schema;
          document.head.appendChild(scriptTag);
        }
        if (isPlatformBrowser(this.platformId) && resp.setting?.analytics) {
          const script = document.createElement('script');
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${resp.setting?.analytics}`;
          document.head.appendChild(script);

          const inlineScript = document.createElement('script');
          inlineScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${resp.setting?.analytics}');
          `;
          document.head.appendChild(inlineScript);
        }
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }

  // fetchNavData(): void {
  //   const lang = this.languageService.currentLanguage;
  //   let URL= URL_SERVICEIO+"/frontEnd/menus?lang="+lang;
  //   this.http.get<any>(URL).subscribe(data => {
  //     this.navDataSubject.next(data);  // Cập nhật dữ liệu vào subject
  //   });
  // }

  // Observable để lấy dữ liệu navData cho các component khác
  // getNavData(): Observable<any> {
  //   return this.navDataSubject.asObservable();  // Trả về Observable để các component subscribe
  // }

  public updateMetaTags(title:string, description: string, photo: string, titleSite:string) {
    const lang = this.languageService.currentLanguage;
    this.document.documentElement.lang = lang;
    const currentUrl = this.document.location.href;
    
    this.titleService.setTitle(title+" - "+URL_WEBSITE);
    this.metaService.updateTag({ property: 'og:site_name', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: photo });
    this.metaService.updateTag({ property: 'og:url', content: currentUrl });

    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    this.metaService.updateTag({ name: 'twitter:image', content: photo });
  }
  public updateMetaTags404(title:string, description: string, photo: string, titleSite:string) {
    const setting=this.settingsSubject.getValue();
    const lang = this.languageService.currentLanguage;
    this.document.documentElement.lang = lang;
    const currentUrl = this.document.location.href;
    this.titleService.setTitle(title+" - "+URL_WEBSITE);
    this.metaService.updateTag({ property: 'og:site_name', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'description', content: setting.description });
    this.metaService.updateTag({ property: 'og:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ property: 'og:description', content: setting.description });
    this.metaService.updateTag({ property: 'og:image', content: setting.logo });
    this.metaService.updateTag({ property: 'og:url', content: currentUrl });

    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'twitter:description', content: setting.description });
    this.metaService.updateTag({ name: 'twitter:image', content: setting.logo });
    this.metaService.updateTag({ name: 'robots', content: "noodp,noindex,noindex" });
  }
  private updateGA() {
    const settings=this.settingsSubject.getValue();
    if (settings?.analyticsId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${settings.analyticsId}`;
      document.head.appendChild(script);

      const inlineScript = document.createElement('script');
      inlineScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${settings.analyticsId}');
      `;
      document.head.appendChild(inlineScript);
    }
  }

  updateSchema(schema:any): void {
    if (isPlatformBrowser(this.platformId) && schema) {
      const scriptTag = document.createElement('script');
      scriptTag.type = 'application/ld+json';
      scriptTag.text = schema;
      document.head.appendChild(scriptTag);
    }
  }
}