import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SitemapService } from 'src/app/services/sitemap.service';

@Injectable({
  providedIn: 'root',
})
export class SitemapResolver implements Resolve<any> {
  constructor(private sitemapService: SitemapService) {}

  resolve() {
    return forkJoin({
        newsCate: this.sitemapService.getNewsCates(),
        news: this.sitemapService.getNews(),
        blog: this.sitemapService.getBlogs(),
        categoriesProducts: this.sitemapService.getCategoriesProducts(),
        products: this.sitemapService.getProducts(1), // Lấy trang đầu tiên
        brands: this.sitemapService.getBrands(),
    });
  }
}