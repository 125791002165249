import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { URL_SERVICEIO } from 'src/app/config/config';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private apiUrl = `${URL_SERVICEIO}/frontEnd/products`; // Endpoint API sản phẩm
  private maxPriceUrl = `${URL_SERVICEIO}/frontEnd/products/max-price`; // Endpoint API giá cao nhất

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private languageService: LanguageService
  ) {}

  /**
   * Thêm tham số ngôn ngữ vào HttpParams
   * @param params HttpParams ban đầu
   * @param lang Ngôn ngữ hiện tại
   * @returns HttpParams với tham số ngôn ngữ
   */
  private appendLangToParams(params: HttpParams, lang: string): HttpParams {
    return params.set('lang', lang);
  }

  /**
   * Lấy danh sách tất cả thương hiệu
   * @param page Số trang
   */
  getAllBrand(page: number): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/brands`;
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams().set('page', page), lang);
        return this.http.get(URL, { params });
      })
    );
  }

  /**
   * Lấy chi tiết một thương hiệu
   * @param id ID hoặc slug của thương hiệu
   */
  getBrandsDetail(id: string): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/brands/${id}`;
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams(), lang);
        return this.http.get(URL, { params }).pipe(
          tap((resp: any) => {
            this.settingsService.settings$.subscribe((settings: any) => {
              const titleCustom = resp?.brand?.title ? resp.brand?.title : resp.brand?.name;
              const descriptionCustom = resp.brand?.description ? resp.brand?.description : settings?.description;
              const imageUrl = resp.brand?.photo ? resp.brand?.photo : settings?.logo;
              this.settingsService.updateMetaTags(titleCustom, descriptionCustom, imageUrl, settings.title);
            });
          })
        );
      })
    );
  }

  /**
   * Lấy danh mục sản phẩm của thương hiệu
   * @param brandId ID của thương hiệu
   * @param page Số trang
   */
  getCategoriesForBrand(brandId: string, page: number): Observable<any> {
    const URL = `${URL_SERVICEIO}/frontEnd/brands/categories/${brandId}`;
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams().set('page', page), lang);
        return this.http.get(URL, { params });
      })
    );
  }

  /**
   * Lấy danh sách sản phẩm của thương hiệu
   * @param page Số trang
   * @param id ID của thương hiệu
   */
  getProductsBrand(page: number, id: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams().set('brand_id', id).set('page', page),
          lang
        );
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }

  /**
   * Lấy giá cao nhất trong sản phẩm của thương hiệu
   * @param id ID của thương hiệu
   */
  getMaxPrice(id: string): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(new HttpParams().set('brand_id', id), lang);
        return this.http.get<any>(this.maxPriceUrl, { params });
      })
    );
  }

  /**
   * Lọc sản phẩm theo giá
   * @param minPrice Giá thấp nhất
   * @param maxPrice Giá cao nhất
   * @param page Số trang
   * @param id ID của thương hiệu
   */
  filterProductsBrand(
    minPrice: number,
    maxPrice: number,
    page: number,
    id: string
  ): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap((lang) => {
        const params = this.appendLangToParams(
          new HttpParams()
            .set('minPrice', minPrice)
            .set('maxPrice', maxPrice)
            .set('brand_id', id)
            .set('page', page),
          lang
        );
        return this.http.get<any>(this.apiUrl, { params });
      })
    );
  }
}
