<main class="bg-gray-100 py-3">
    <div class="container">
        <section class="breadcrumb__area include-bg mb-3">
            <div class="breadcrumb__content position-relative z-index-1">
               <div class="breadcrumb__list mb-3">
                  <span><a routerLink="/">{{"trangchu" | translate}}</a></span>
                  <span>{{"timkiemsanpham" | translate}}</span>
               </div>
               <h1 class="breadcrumb__title">{{"timkiemsanpham" | translate}} - "{{ keywordDecode }}"</h1>
            </div>
        </section>
        <div class="grid_container1">
            <div class="columns-md-2 columns-xs-6 mb-4 item_product bg-white" *ngFor="let product of products">
                <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                <div class="images">
                    <a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                        <img *ngIf="product.image.thumbnail; else usePhoto" [src]="product.image.thumbnail" alt="{{product.name}}" class="img-fluid">
                        <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                        <ng-template #usePhoto>
                        <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                        </ng-template>
                    </a>
                </div>
                <div class="info p-4 py-2">
                <div class="tp-product-category"><a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                <a routerLink="/{{currentLanguage}}/{{ product.categorie_first.slug }}/{{ product.slug }}-tjk{{ product.id }}">
                        <h3>{{ product.name }}</h3>
                    </a>
                    <div class="tp-product-rating-icon">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
                <div class="price">
                        <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                        <strong *ngIf="product.priceSale > 0">
                            {{ product.priceSale | number:'1.0-0' }} đ
                        </strong>
                        <strong *ngIf="product.priceSale <= 0">
                            Liên hệ
                        </strong>
                    </div>
                </div>
            </div>
        </div>
            <ngb-pagination *ngIf="totalPages/20>1"
                [collectionSize]="totalPages"
                [(page)]="currentPage"
                [pageSize]="20"
                [rotate]="true"
                [boundaryLinks]="true"
                (pageChange)="loadPage($event)"
                [maxSize]="5"
        ></ngb-pagination>
    </div>
</main>