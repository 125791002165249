import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../services/language/language.service';

@Directive()
export class Base implements OnInit, OnDestroy {
  currentLanguage: "vi" | "en" | "cn" = "vi";
  private langSubscription: Subscription | null = null;

  constructor(public languageService: LanguageService) {}

  ngOnInit(): void {
    this.langSubscription = this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    console.log(this.currentLanguage,"lang");
  }

  ngOnDestroy(): void {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}