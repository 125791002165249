import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { SharedDataServiceService } from 'src/app/services/shared-data-service.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { HomeService } from '../../home/service/home.service';
import { ApiLoadServiceService } from 'src/app/services/api-load-service.service';

declare var $: any;
declare function InitNav([]): any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent {
  @Input() openNav: boolean = false;
  @Output() closeNav = new EventEmitter<void>();
  openMenus: { [key: string]: boolean } = {};
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';

  categories: any[] = [];
  categories_first: any[] = [];
  categoryTree: any[] = [];
  abouts: any[] = [];
  services: any[] = [];
  giaiphaps: any[] = [];
  tintucs: any[] = [];

  dataLoaded: boolean = false; // Trạng thái dữ liệu đã được tải
  loadChild: boolean = false;

  constructor(
    private homeService: HomeService,
    public languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sharedDataService: SharedDataServiceService,
    private apiLoadService: ApiLoadServiceService
  ) {}

  ngOnInit(): void {
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.waitForBaseApisToLoad().then(() => {
      this.loadNavData();
    });
    //this.loadNavData();

    // Lắng nghe thay đổi ngôn ngữ
    // setTimeout(() => {
    //   this.languageService.language$.subscribe((lang) => {
    //     this.currentLanguage = lang;
    //     this.loadNavData(); // Gọi lại dữ liệu khi thay đổi ngôn ngữ
    //   });
    // });
    

    // Khởi tạo menu khi chạy trên trình duyệt
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        InitNav($);
      }, 50);
    }
  }
  private waitForBaseApisToLoad(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.apiLoadService.getBaseApisLoaded()) {
          clearInterval(interval);
          resolve();
        }
      }, 100); // Kiểm tra mỗi 100ms
    });
  }
  /**
   * Tải dữ liệu navigation
   */
  private loadNavData(): void {
    this.dataLoaded = false; // Đặt trạng thái là chưa tải xong

    this.homeService.fetchMenus().subscribe((data:any) =>{
      if (data) {
        this.abouts = data.aboutUs;
        this.services = data.serviceCategorie;
        this.giaiphaps = data.giaiphapCategorie;
        this.tintucs = data.newCategorie;
      }
    });
    
    this.homeService.fetchCategories().subscribe((data:any) =>{
      if (data) {
        this.categories = data.categories;
        this.categoryTree = data.categories;
        this.sharedDataService.updateCategories(this.categories);
        this.dataLoaded = true;
      }
    }); // Yêu cầu API tải danh mục
    // this.homeService.menus$.subscribe((data) => {
    //   if (data) {
    //     this.categories = data.categories;
    //     this.categoryTree = data.categories;
    //     this.sharedDataService.updateCategories(this.categories);
    //   }
    // });
  }
  loadSubcategories(): void {
    // Kiểm tra nếu danh mục con đã được tải
    // if (this.categoryTree) {
    //   return;
    // }
    if(this.loadChild==false){
      // Gọi API để lấy danh mục con
      this.homeService.fetchCategoryChilds();
      this.homeService.menusChild$.subscribe((data) => {
        if(data){
          this.categoryTree = this.buildCategoryTree(this.categories,data.categories);
        }
      });
      this.loadChild=true;
    }
    this.loadChild=true;
  }

  /**
   * Xây dựng cây danh mục từ danh sách danh mục
   */
  private buildCategoryTree(category_firsts: any[], category_childs: any[]): any[] {
    const categoryMap: any = {};

    // Tạo một map danh mục con theo id để dễ dàng truy cập
    category_childs.forEach((category) => {
        category.children = [];
        categoryMap[category.id] = category;
    });

    const tree: any[] = [];

    // Xây dựng cây danh mục từ danh mục cấp 1
    category_firsts.forEach((category) => {
        category.children = [];
        tree.push(category);

        // Tìm các danh mục con của danh mục cấp 1
        category_childs.forEach((child) => {
            if (child.id_parent === category.id) {
                category.children.push(child);

                // Tìm các danh mục con của cấp 2
                category_childs.forEach((subChild) => {
                    if (subChild.id_parent === child.id) {
                        child.children.push(subChild);
                    }
                });
            }
        });
    });

    return tree;
}

  closeMenu() {
    this.closeNav.emit();
  }

  toggleDropdown(menu: string) {
    this.openMenus[menu] = !this.openMenus[menu];
  }

  isOpen(menu: string): boolean {
    return !!this.openMenus[menu];
  }

  handleHover(id: number): void {
    this.loadSubcategories();
    const elements = document.querySelectorAll('.subcategorie');
    elements.forEach(el => (el as HTMLElement).style.display = 'none');

    const targetElement = document.querySelector(`.subcategorie_${id}`);
    if (targetElement) {
      (targetElement as HTMLElement).style.display = 'block';
    }
  }
  // handleHover(id: number): void {
  //   const elements = document.querySelectorAll('.subcategorie');
  //   elements.forEach(el => (el as HTMLElement).style.display = 'none');

  //   const targetElement = document.querySelector(`.subcategorie_${id}`);
  //   if (targetElement) {
  //     (targetElement as HTMLElement).style.display = 'block';
  //   }
  // }
}
