import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrl: './product-category.component.css'
})
export class ProductCategoryComponent {
  @Input() categories: any[] = [];
  @Input() why: any[] = [];
  @Input() currentLanguage: any = '';
  @Input() isLoading: boolean = true;
}
