import { Component, Input } from '@angular/core';
import { SharedDataServiceService } from 'src/app/services/shared-data-service.service';
import { ProductService } from '../../product/service/product.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-left-product',
  templateUrl: './left-product.component.html',
  styleUrl: './left-product.component.css'
})
export class LeftProductComponent {
  productSale:any=[];
  isLoading = true;
  skeletonArray = Array(8);
  @Input() categories: any[] | undefined;
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  constructor(
    private productService: ProductService,
    private sharedDataService: SharedDataServiceService,
    private route: ActivatedRoute,
    private languageService: LanguageService
  ){}

  ngOnInit(): void {
    //this.productSale = this.route.snapshot.data['leftData'].productSale;
    //if(this.productSale) this.isLoading=false;
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    this.getCategories();
    this.sharedDataService.categories$.subscribe(categories => {
      this.categories = categories;  // Nhận dữ liệu categories từ SharedDataService
    });
  }
  getCategories(){
    this.productService.getCategories().subscribe((resp:any) =>{
      //this.categories=resp.categories;
      this.productSale=resp.productSale;
      this.isLoading=false;
    })
  }
}
