import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { URL_WEBSITE } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {
  currentLanguage: 'vi' | 'en' | 'cn' = 'vi';
  cartItems: any[] = [];
  settings:any=[];
  title:string="";

  constructor(private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef,
    public languageService: LanguageService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.updateTitle();
    this.languageService.language$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
    // this.languageService.language$.subscribe(() => {
    //   this.updateTitle();
    // });
    this.loadCartItems();
  }
  private updateTitle(): void {
    this.translate.get('giohang').subscribe((res: string) => {
      this.title = res; // Cập nhật tiêu đề trong component
      this.settingsService.settings$.subscribe(
        (data) => {
          if(data){
            this.settings = data;
            this.settingsService.updateMetaTags(this.title, this.settings.description, this.settings.logo, this.settings.title);
          }
        },
        (error) => {
          console.error('Error fetching settings:', error);
        }
      );
    });
  }
  loadCartItems(): void {
    this.cartItems = this.cartService.getCartItems();
    this.cdr.detectChanges();
  }

  removeFromCart(productId: number, product_variation_id: number): void {
    this.translate.get('xacnhanxoa').subscribe((message: string) => {
      const confirmed = confirm(message); // Sử dụng chuỗi đã dịch
      if (confirmed) {
        this.cartService.removeFromCart(productId, product_variation_id);
        this.loadCartItems(); // Reload items after removal
      }
    });
    // const confirmed = confirm('Bạn có chắc chắn muốn xóa sản phẩm này khỏi giỏ hàng?');
    // if (confirmed) {
    //   this.cartService.removeFromCart(productId, product_variation_id);
    //   this.loadCartItems(); // Reload items after removal
    // }
    
  }

  updateQuantity(item:any) {
    this.cartService.updateQuantity(item);
  }

  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => total + item.price*item.quantity, 0);
  }

  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {console.log(this.title);
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      //this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
