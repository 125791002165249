<main class="bg-gray-100 py-5">

    <!-- profile area start -->
    <section class="profile__area pt-120 pb-120">
     <div class="container">
           <div class="row">
              <div class="col-xxl-4 col-lg-4">
                 <div class="profile__tab me-5 bg-white">
                  <a class="nav-link " routerLink="/{{currentLanguage}}/profile"><span><i class="fas fa-user"></i></span>{{"thongtintaikhoan" | translate}}</a>
                  <a class="nav-link " routerLink="/{{currentLanguage}}/lich-su-mua-hang"><span><i class="fas fa-clipboard-list-check"></i></span>{{"lichsumuahang" | translate}}</a>
                  <a class="nav-link active" routerLink="/{{currentLanguage}}/change-pass"><span><i class="fas fa-lock"></i></span>{{"doimatkhau" | translate}}</a>
                  <a class="nav-link" routerLink="/{{currentLanguage}}/logout"><span><i class="fas fa-times"></i></span>{{"thoat" | translate}}</a>
                    <span id="marker-vertical" class="tp-tab-line d-none d-sm-inline-block"></span>
                 </div>
              </div>
              <div class="col-xxl-8 col-lg-8">
                <div class="profile_form bg-white p-md-5 p-3">
                    <table class="table">
                        <thead>
                          <tr>
                            <th class="tp-cart-header-product">{{"madonhang" | translate}}</th>
                            <th class="tp-cart-header-price">{{"soluong" | translate}}</th>
                            <th class="tp-cart-header-quantity">{{"ngaydat" | translate}}</th>
                            <th class="tp-cart-header-price">{{"tongtien" | translate}}</th>
                            <th class="tp-cart-header-price">{{"trangthai" | translate}}</th>
                            <!-- <th></th> -->
                          </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let item of orders">
                                <td class="tp-cart-title">{{ item.madonhang }}</td>
                                <td class="tp-cart-title">{{ item.order_details_count }}</td>
                                <td class="tp-cart-title">{{ item.created_at }}</td>
                                <td class="tp-cart-title"><ng-container *ngIf="item.total_price">{{ item.total_price | number:'1.0-0' }} đ </ng-container></td>
                                <td class="tp-cart-title {{ getStatusclass(item.trangthai) }}">{{ getStatusText(item.trangthai)}}</td>
                              <!-- action -->
                                <!-- <td class="tp-cart-action">
                                 <button class="tp-cart-action-btn">
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 1.53033C9.82322 1.23744 9.82322 0.762563 9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967L5 3.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L3.93934 5L0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L5 6.06066L8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L6.06066 5L9.53033 1.53033Z" fill="currentColor"></path>
                                    </svg>
                                    <span>Remove</span>
                                 </button>
                                </td> -->
                           </tr>
                        </tbody>
                      </table>
                    <ngb-pagination *ngIf="totalPages/16>1"
                        [collectionSize]="totalPages"
                        [(page)]="currentPage"
                        [pageSize]="16"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="loadPage($event)"
                        [maxSize]="5"
                    ></ngb-pagination>
                </div>
              </div>
           </div>
        </div>
    </section>
 <!-- profile area end -->

  </main>