import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    CheckoutComponent
  ],
  imports: [
    CommonModule,FormsModule, RouterModule, ReactiveFormsModule, TranslateModule
  ]
})
export class CheckoutModule { }
