// custom-url-matchers.ts

import { UrlSegment, UrlMatchResult } from '@angular/router';

// Custom URL Matcher cho thương hiệu: domain/slugCategorie/slugBrand
export function brandUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  // Kiểm tra xem URL có đúng cấu trúc với 3 phần tử
  if (url.length === 3) {
    return {
      consumed: url,
      posParams: {
        language: url[0], // Ngôn ngữ
        slugCategorie: url[1], // Slug danh mục
        slugBrand: url[2] // Slug thương hiệu
      }
    };
  }
  return null; // Không khớp
}

// Custom URL Matcher cho danh mục sản phẩm: domain/slugCategorie-cIdCategorie
export function categoryUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 2) {
    const languageSegment = url[0].path;
    const categorySegment = url[1].path;
    const languageRegex = /^(vi|en|kr)$/;
    if (!languageRegex.test(languageSegment)) {
      return null;
    }

    const regex = /^([a-zA-Z0-9-]+)-c(\d+)$/;
    const match = categorySegment.match(regex);
    if (match) {
      return {
        consumed: url,
        posParams: {
          language: new UrlSegment(languageSegment, {}),
          slugCategorie: new UrlSegment(match[1], {}), 
          idCategorie: new UrlSegment(match[2], {}) 
        }
      };
    }
  }
  return null;
}

// Custom URL Matcher cho danh mục tin tức: domain/slugDanhmuc
export function newsCategoryUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 2) {
    const languageSegment = url[0].path; // Segment đầu tiên (ngôn ngữ)
    const categorySegment = url[1].path; // Segment thứ hai (danh mục tin tức)

    return {
      consumed: url,
      posParams: {
        language: new UrlSegment(languageSegment, {}), // Ngôn ngữ
        slugDanhmuc: new UrlSegment(categorySegment, {}) // Danh mục tin tức
      }
    };
  }
  return null;
}

export function productUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  // Kiểm tra độ dài URL: phải có 3 phần tử
  if (url.length === 3) {
    const languageSegment = url[0].path; // Ngôn ngữ
    const productSegment = url[1].path; // Phần cố định "san-pham"
    const slugSegment = url[2].path; // Slug và ID

    // Kiểm tra ngôn ngữ hợp lệ
    const languageRegex = /^(vi|en|cn)$/;
    if (!languageRegex.test(languageSegment)) {
      return null;
    }

    // Kiểm tra phần "san-pham"
    if (productSegment !== 'san-pham') {
      return null;
    }

    // Kiểm tra slug và ID
    const slugRegex = /^([a-zA-Z0-9-]+)-tjk(\d+)$/;
    const match = slugSegment.match(slugRegex); console.log(match);
    if (match) {
      return {
        consumed: url,
        posParams: {
          language: new UrlSegment(languageSegment, {}), // Ngôn ngữ
          slug: new UrlSegment(match[1], {}), // Slug sản phẩm
          id: new UrlSegment(match[2], {}) // ID sản phẩm
        }
      };
    }
  }
  return null; // Không khớp
}

// Custom URL Matcher cho chi tiết tin tức: domain/slugDanhmuc/slugTintuc-pIdTintuc
// export function newsDetailUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
//   if (url.length === 2) {
//     const regex = /^([a-zA-Z0-9-]+)-p(\d+)$/;
//     const match = url[1].path.match(regex);
//     if (match) {
//       return {
//         consumed: url,
//         posParams: {
//           slugDanhmuc: url[0],
//           slugTintuc: new UrlSegment(match[1], {}),
//           idTintuc: new UrlSegment(match[2], {})
//         }
//       };
//     }
//   }
//   return null;
// }
export function newsDetailUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  // Kiểm tra nếu có ít nhất 3 segment và segment cuối cùng kết thúc bằng ".html"
  console.log(url);
  if (url.length === 3 && url[2].path.endsWith('.html')) {
    const languageSegment = url[0].path; // Segment đầu tiên (ngôn ngữ)
    const categorySegment = url[1].path; // Segment thứ hai (danh mục tin tức)
    const lastSegment = url[2].path; // Segment cuối cùng (chi tiết bài viết)
    console.log(url);

    // Kiểm tra cú pháp của segment cuối cùng
    const regex = /^([a-zA-Z0-9-]+)-p(\d+)\.html$/;
    const match = lastSegment.match(regex);

    if (match) {
      const slugNew = match[1]; // Phần slug của tin tức
      const id = match[2]; // Phần id của tin tức

      return {
        consumed: url,
        posParams: {
          language: new UrlSegment(languageSegment, {}), // Ngôn ngữ (segment đầu tiên)
          slugNewCategory: new UrlSegment(categorySegment, {}), // Danh mục tin tức (segment thứ hai)
          slugNew: new UrlSegment(slugNew, {}), // Slug của tin tức
          idTintuc: new UrlSegment(id, {}) // ID của tin tức
        }
      };
    }
  }

  return null; // Trả về null nếu không khớp
}