import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductService } from '../product/service/product.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class leftResolver implements Resolve<any> {
  constructor(private productService: ProductService,private languageService: LanguageService) {}

  resolve(): Observable<any> {
    return this.languageService.language$.pipe(
      switchMap(() => this.productService.getCategories()) // Gọi API khi ngôn ngữ thay đổi
    );
  }
  // resolve(): Observable<any> {
  //   return this.productService.getCategories();  // Gọi API để tải danh sách danh mục
  // }
}